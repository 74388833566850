import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MRT_RowSelectionState as MRTRowSelectionState,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { Tooltip, Box, IconButton, Paper, Stack, Typography, Avatar } from '@mui/material';
import { SaveAlt as SaveAltIcon, Smartphone as SmartphoneIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

import AndroidIcon from '../../assets/images/android-logo.png';
import AppleIcon from '../../assets/images/apple-logo.png';
import WindowsIcon from '../../assets/images/windows-logo.png';
import { useNavigate } from '../../hooks/useNavigate';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { tokenListSchema } from './schema';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useFormatDate } from '../../hooks/useFormatDate';
import { GetTokenDetailResponseDto, TokenDetailDto } from '../../api/Api';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { ESystemName } from './enums';

export const TokenList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [rowSelection, setRowSelection] = React.useState<MRTRowSelectionState>({});
	const [tokenIDToDelete, setTokenIDToDelete] = React.useState<number | null>(null);
	const [multiTokenIDToDelete, setMultiTokenIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['userFullName', 'deviceName', 'device', 'platform', 'createdAt']);

	const { data, isRefetching, isLoading, error } = useQuery<GetTokenDetailResponseDto>({
		queryKey: [EQueryKey.TOKEN_DETAIL_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filters: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};

				const response = await api.tokens.getTokensDetail(query);

				response.data.entities.forEach((token) => {
					tokenListSchema.parse(token);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.token.list.confirmation.delete.title'));
				setConfirmationText(t('page.token.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.token.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.token.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenTokenDeleteDialog = React.useCallback(
		(tokenID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();

			setOpen(true);
			setTokenIDToDelete(tokenID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed, entities],
	);

	const handleOnOpenMultipleTokensDelete = React.useCallback(
		(table: MRTTableInstance<Partial<TokenDetailDto>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.token.list.errorMessages.noTokenSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}

			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiTokenIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed, entities],
	);

	const handleOnConfirmGroupDelete = React.useCallback(async () => {
		if (tokenIDToDelete) {
			try {
				await api.tokens.deleteToken(tokenIDToDelete);
				enqueueSnackbar(t('page.token.list.actionMessages.tokenSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				reactQueryClient.invalidateQueries();
				setOpen(false);
				setTokenIDToDelete(null);
				updateRowSelection([tokenIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiTokenIDToDelete.ids.length > 0) {
			try {
				await api.tokens.deleteTokens(multiTokenIDToDelete);
				enqueueSnackbar(t('page.token.list.actionMessages.tokensSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});

				reactQueryClient.invalidateQueries();
				setOpen(false);
				setMultiTokenIDToDelete({ ids: [] });
				updateRowSelection(multiTokenIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else {
			setOpen(false);
		}
	}, [tokenIDToDelete, multiTokenIDToDelete, isAllowed]);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	const getPlatformLogoBySystemName = React.useCallback(
		(systemName: string | undefined): string => {
			if (!systemName) {
				return '';
			}
			const systemLogos = new Map<string, string>([
				[ESystemName.ANDROID, AndroidIcon],
				[ESystemName.IOS, AppleIcon],
				[ESystemName.IPADOS, AppleIcon],
				[ESystemName.WINDOWS, WindowsIcon],
			]);

			const logo = systemLogos.get(systemName.trim().toLocaleLowerCase());

			return logo ? logo : '';
		},
		[ESystemName],
	);

	const formatSystemName = React.useCallback(
		(systemName: string | undefined): string => {
			if (!systemName) {
				return t('page.token.detail.body.unknown');
			}
			const formattedNames = new Map<string, string>([
				[ESystemName.ANDROID, 'Android'],
				[ESystemName.IOS, 'iOS'],
				[ESystemName.IPADOS, 'iPadOS'],
				[ESystemName.WINDOWS, 'Windows'],
			]);

			const name = formattedNames.get(systemName.trim().toLocaleLowerCase());

			return name ? name : t('page.token.detail.body.unknown');
		},
		[ESystemName],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRTColumnDef<Partial<TokenDetailDto>>[]>(
		() => [
			{
				accessorKey: 'deviceName',
				header: t('page.token.list.table.header.deviceName'),
			},
			{
				accessorFn: (row) => `${row.brand} ${row.model}`,
				accessorKey: 'device',
				header: t('page.token.list.table.header.device'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.systemName} ${row.systemVersion}`,
				accessorKey: 'platform',
				header: t('page.token.list.table.header.platform'),
				Cell: ({ renderedCellValue, row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<img
							alt={t('page.token.list.table.body.logo')}
							height={30}
							src={getPlatformLogoBySystemName(row.original.systemName)}
							loading='lazy'
							style={{ borderRadius: 'none' }}
						/>
						<span>{`${formatSystemName(row.original.systemName)} ${row.original.systemVersion}`}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				header: t('page.token.list.table.header.createdAt'),
				minSize: 340,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
			{
				accessorFn: (user) =>
					`${user.userTitle ? `${user.userTitle} ` : ''}${user.userName} ${user.userSurname ? user.userSurname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.token.list.table.header.user'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={String(renderedCellValue)} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
		],
		[isAllowed, entities],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.token.list.title')}
						description={t('page.token.list.description')}
						icon={SmartphoneIcon}
					/>
					<Stack>
						<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
							<MaterialReactTable
								layoutMode='grid'
								columns={columns}
								data={entities}
								enableRowActions
								enableStickyHeader={false}
								state={{
									isLoading: isLoading,
									showAlertBanner: error !== null,
									pagination,
									rowSelection,
									showProgressBars: isRefetching,
									columnFilters,
									globalFilter,
									sorting,
									columnVisibility,
								}}
								muiToolbarAlertBannerProps={{
									color: 'error',
									children: <>{error}</>,
								}}
								initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
								rowCount={total}
								manualPagination
								manualFiltering
								manualSorting
								onSortingChange={setSorting}
								onGlobalFilterChange={setGlobalFilter}
								onColumnFiltersChange={setColumnFilters}
								onPaginationChange={setPagination}
								onColumnVisibilityChange={setColumnVisibility}
								enableRowSelection
								getRowId={(originalRow) => originalRow.id?.toString() || ''}
								onRowSelectionChange={setRowSelection}
								renderRowActions={({ row }) => (
									<Box sx={{ display: 'flex', justifyContent: 'left', gap: '1rem' }}>
										{isAllowed(
											[EPermission.TOKENS_DELETE_ALL, EPermission.TOKENS_DELETE_OWN],
											false,
										) && (
											<Tooltip
												title={t('page.token.list.tooltips.deleteToken')}
												placement='right'
												enterDelay={500}
												arrow
											>
												<IconButton
													color='error'
													onClick={handleOnOpenTokenDeleteDialog(row.original.id as number)}
												>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										)}
									</Box>
								)}
								renderToolbarInternalActions={({ table }) => (
									<Box sx={{ display: 'flex', gap: '1rem' }}>
										<MRTToggleGlobalFilterButton table={table} />
										<MRTToggleFiltersButton table={table} />
										<MRTShowHideColumnsButton table={table} />
										{/* <Tooltip title={t('page.token.list.tooltips.export')} enterDelay={500}>
											<IconButton>
												<SaveAltIcon />
											</IconButton>
										</Tooltip> */}
										{isAllowed(
											[EPermission.TOKENS_DELETE_ALL, EPermission.TOKENS_DELETE_OWN],
											false,
										) && (
											<Tooltip
												title={t('page.token.list.tooltips.removeSelected')}
												enterDelay={500}
											>
												<span>
													<IconButton
														color='error'
														disabled={table.getSelectedRowModel().rows.length === 0}
														onClick={handleOnOpenMultipleTokensDelete(table)}
													>
														<DeleteIcon />
													</IconButton>
												</span>
											</Tooltip>
										)}
										<MRTToggleDensePaddingButton table={table} />
										<MRTFullScreenToggleButton table={table} />
									</Box>
								)}
								displayColumnDefOptions={{
									'mrt-row-actions': {
										header: t('page.token.list.table.header.actions'),
									},
									'mrt-row-select': {
										enableHiding: true,
										visibleInShowHideMenu: false,
									},
								}}
								muiTablePaperProps={({ table }) => ({
									style: {
										zIndex: table.getState().isFullScreen ? 1250 : undefined,
										boxShadow: 'none',
										outline: '1px solid #e0e0e0',
									},
								})}
								muiSelectCheckboxProps={() => ({
									sx: {
										width: '50px',
										height: '50px',
									},
								})}
								muiSelectAllCheckboxProps={() => ({
									sx: {
										width: '50px',
										height: '50px',
									},
								})}
								muiTableHeadCellProps={() => ({
									sx: {
										verticalAlign: 'baseline',
									},
								})}
								editDisplayMode='modal'
								localization={MRTLocalization}
								positionActionsColumn='last'
								muiTableBodyRowProps={({ row }) => ({
									onClick:
										isAllowed([EPermission.TOKENS_READ_ALL, EPermission.TOKENS_READ_OWN], false) ?
											handleRedirect(`/tokens/${row.original.id}`)
										:	undefined,
									sx: { cursor: 'pointer' },
								})}
							/>
						</LocalizationProvider>
					</Stack>
				</Stack>
			</Paper>

			{isAllowed([EPermission.TOKENS_DELETE_ALL, EPermission.TOKENS_DELETE_OWN], false) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmGroupDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.token.list.confirmation.delete.cancel')}
					confirmText={t('page.token.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
