import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography, Grid, Button, TextField as OriginalTextField, Stack, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getCSRSetupSchema } from '../schemas';
import { parseLicenseContent } from '../../../utils/FileHelpers';
import { CsrState, CSRSetupProps, CSRFormState, CSRFormValues } from '../types';
import { ESetupSteps } from '../enums';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { FileUploader } from '../../../components/FormFields/FileUploader/FileUploader';
import { SUPPORTED_FILE_TYPES_LICENSE, WATCHED_KEYS } from '../constants';
import { SEO } from '../../../components/SEO/SEO';
import { LicenseKeyInput } from '../../../components/FormFields/LicenseKeyInput/LicenseKeyInput';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useKeyPress } from '../../../hooks/useKeyPress';

export const CSRSetup: React.FC<CSRSetupProps> = (props): JSX.Element => {
	useScrollToTop();
	const device = useDeviceDetect();
	const { setIndexPage, getCurrentStep } = props;
	const [licenseState, setLicenseState] = React.useState<Record<any, any>>({});
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const formatDate = useFormatDate();

	const [csrState, setCsrState] = React.useState<CsrState>({
		creating: false,
		created: false,
		error: null,
	});

	const {
		handleSubmit,
		getValues,
		register,
		trigger,
		control,
		reset,
		setError,
		formState: { errors },
	} = useForm<CSRFormState>({
		mode: 'onChange',
		resolver: zodResolver(getCSRSetupSchema(t)),
	});

	const file = useWatch({ control: control, name: 'file', defaultValue: null });

	const handleOnSubmit = React.useCallback(
		async (formValues: CSRFormValues) => {
			if (csrState.creating) {
				return;
			}
			setCsrState({
				created: false,
				creating: true,
				error: null,
			});
			try {
				await api.setup.createCsr({
					licenseFile: formValues.licenseFile,
					licenseKey: formValues.licenseKey,
				});
				setCsrState({
					created: true,
					creating: false,
					error: null,
				});
				enqueueSnackbar(t('page.setupWizard.actionMessages.caSuccessfulltyCreated'), {
					variant: 'success',
					persist: false,
				});
				setIndexPage(getCurrentStep(ESetupSteps.SERVER_SETUP));
			} catch (error) {
				setCsrState({
					created: true,
					creating: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[licenseState],
	);

	const handleKeyDown = useKeyPress(WATCHED_KEYS, handleSubmit(handleOnSubmit));

	const handleChange = React.useCallback(async (file: File): Promise<void> => {
		if (!(file instanceof File)) {
			enqueueSnackbar(t('page.setupWizard.actionMessages.noSelectedFile'), {
				variant: 'error',
				persist: false,
			});

			return;
		}

		const selectedFile = file;

		const isValid = await trigger('file');

		if (!isValid) {
			return;
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			if (e.target) {
				const content = e.target.result as string;
				const currentValues = getValues();
				try {
					const decodedContent = atob(content);
					const licenseContent = parseLicenseContent(decodedContent);
					if (!licenseContent.companyName || !licenseContent.deploymentName) {
						enqueueSnackbar(t('page.setupWizard.actionMessages.invalidLicenseFile'), {
							variant: 'error',
							persist: false,
						});

						return;
					}
					setLicenseState(licenseContent);
					reset({ ...currentValues, licenseFile: content });
				} catch (error) {
					setError('licenseFile', {
						type: 'manual',
						message: t('page.setupWizard.validationMessages.licenseKey.invalid'),
					});
					enqueueSnackbar(t('page.setupWizard.validationMessages.licenseKey.invalid'), {
						variant: 'error',
						persist: false,
					});
				}
			}
		};
		reader.readAsText(selectedFile);
	}, []);

	React.useEffect(() => {
		if (!file || !!errors.file) {
			return;
		}
		handleChange(file);
	}, [file, errors.file]);

	return (
		<Box>
			<SEO
				title={t('page.setupWizard.texts.CSR.title')}
				description={t('page.setupWizard.texts.CSR.description')}
			/>
			<Stack direction='column' spacing={2}>
				<Typography align='center'>{t('page.setupWizard.texts.CSR.first')}</Typography>

				<Typography align='center'>{t('page.setupWizard.texts.CSR.second')}</Typography>

				<Typography align='center'>{t('page.setupWizard.texts.CSR.third')}</Typography>
				<br />
			</Stack>
			<Stack direction='column' spacing={2} sx={{ marginBottom: 2 }}>
				{Object.entries(licenseState).map(([key, value]) => (
					<Typography key={key} variant='subtitle2'>
						<strong>{t(`page.setupWizard.texts.CSR.${key}`)}</strong>:{' '}
						{`${typeof value === 'string' && key.includes('valid') ? formatDate(value) : value}`}
					</Typography>
				))}
			</Stack>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<OriginalTextField
						style={{
							display: 'none',
						}}
						{...register('licenseFile')}
						label={t('page.setupWizard.texts.CSR.licenseFile.label')}
						type='text'
						variant='outlined'
						disabled={csrState.creating}
						error={!!errors.licenseFile}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{
							readOnly: true,
						}}
						onKeyDown={handleKeyDown}
					/>
				</Grid>
				<Grid item xs={12}>
					<FileUploader
						control={control}
						name={'file'}
						error={errors.file}
						disabled={csrState.creating}
						helperText={t('page.setupWizard.texts.CSR.file.helperText')}
						multiple={false}
						accept={SUPPORTED_FILE_TYPES_LICENSE}
					/>
					{errors.licenseFile && typeof errors.licenseFile.message === 'string' && (
						<FormHelperText sx={{ paddingLeft: 2, color: '#d32f2f' }}>
							{errors.licenseFile.message}
						</FormHelperText>
					)}
				</Grid>
				<Grid item xs={12}>
					<LicenseKeyInput
						{...register('licenseKey')}
						label={t('page.setupWizard.texts.CSR.licenseKey.label')}
						disabled={csrState.creating}
						error={errors.licenseKey}
						onKeyDown={handleKeyDown}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Button
						fullWidth={device !== 'desktop'}
						variant='contained'
						disabled={csrState.creating}
						type='submit'
						onClick={handleSubmit(handleOnSubmit)}
					>
						{t('page.setupWizard.texts.CSR.next')}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
