import { forwardRef } from 'react';
import { Autocomplete, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Waypoint } from 'react-waypoint';

import { UserFilterProps } from './types';
import { useFetchUserFilterOptions } from './useFetchUserOptions';

const ListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
	const { t } = useTranslation();

	const { hasNextPage, isFetchingNextPage, fetchNextPage } = useFetchUserFilterOptions();

	const renderWaypoint = () => {
		if (!isFetchingNextPage && hasNextPage) {
			return <Waypoint onEnter={() => fetchNextPage()} bottomOffset={-300} />;
		} else if (isFetchingNextPage && hasNextPage) {
			return (
				<Typography px={2} pb={1}>
					{t('component.auditLogTimeline.userFilter.loading')}
				</Typography>
			);
		}
	};

	return (
		<div ref={ref}>
			<ul {...props} />
			{renderWaypoint()}
		</div>
	);
});

export const UserFilter: React.FC<UserFilterProps> = ({ userID, setUserID }) => {
	const { t } = useTranslation();

	const { data = [], isLoading } = useFetchUserFilterOptions();

	return (
		<>
			<Typography>{t('component.auditLogTimeline.userFilter.title')}</Typography>
			<Autocomplete
				fullWidth
				id='user-filter'
				loading={isLoading}
				value={data.find((o) => userID === o.id) ?? null}
				limitTags={2}
				ListboxComponent={ListboxComponent}
				onChange={(_, newValue) => setUserID(newValue?.id ?? null)}
				options={data}
				getOptionLabel={(option) => option.label}
				renderInput={({ inputProps, ...rest }) => (
					<TextField
						{...rest}
						label={t('component.auditLogTimeline.userFilter.label')}
						inputProps={{ ...inputProps, readOnly: true }}
					/>
				)}
			/>
		</>
	);
};
