import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RevTypeFilterProps } from './types';
import { revTypeToArray, getRevTypeColor } from './utils';

export const RevTypeFilter: React.FC<RevTypeFilterProps> = ({ revType, setRevType }) => {
	const { t } = useTranslation();

	const revTypeOptions = revTypeToArray(t);

	return (
		<>
			<Typography>{t('component.auditLogTimeline.revTypeFilter.title')}</Typography>
			<Autocomplete
				fullWidth
				id='rev-type-filter'
				value={revTypeOptions.find((o) => revType === o.id) ?? null}
				onChange={(_, option) => setRevType(option?.id ?? null)}
				options={revTypeOptions}
				getOptionLabel={(option) => option.label}
				renderInput={({ inputProps, ...rest }) => (
					<TextField
						{...rest}
						label={t('component.auditLogTimeline.revTypeFilter.label')}
						inputProps={{ ...inputProps, readOnly: true }}
					/>
				)}
				renderOption={(props, option) => {
					return (
						<Box key={option.id} component='li' {...props}>
							<Box
								component='span'
								sx={{
									width: 14,
									height: 14,
									flexShrink: 0,
									borderRadius: '3px',
									mr: 2,
									backgroundColor: getRevTypeColor(option.id),
								}}
							/>
							{option.label}
						</Box>
					);
				}}
			/>
		</>
	);
};
