import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, Grid, Button, FormGroup, Stack, Link } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getVerificationSchema } from '../schemas';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import {
	VerificationState,
	VerificationProps,
	VerificationFormState,
	VerificationFormValues,
	VerificationResendState,
} from '../types';
import { ESetupSteps } from '../enums';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import { OneTimePassword } from '../../../components/FormFields/OneTimePassword/OneTimePassword';
import { SEO } from '../../../components/SEO/SEO';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useKeyPress } from '../../../hooks/useKeyPress';
import { WATCHED_KEYS } from '../constants';

export const Verification: React.FC<VerificationProps> = (props): JSX.Element => {
	useScrollToTop();
	const device = useDeviceDetect();
	const [open, setOpen] = React.useState(false);
	const { setIndexPage, getCurrentStep } = props;
	const [verificationState, setVerificationState] = React.useState<VerificationState>({
		verifying: false,
		verified: false,
		error: null,
	});
	const [verificationResendState, setVerificationResendState] = React.useState<VerificationResendState>({
		resending: false,
		resend: false,
		error: null,
	});
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const handleOnClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<VerificationFormState>({
		mode: 'onChange',
		resolver: zodResolver(getVerificationSchema(t)),
	});

	const handleOnResendVerificationCode = React.useCallback(async () => {
		if (verificationResendState.resending) {
			return;
		}
		setVerificationResendState({
			resending: true,
			resend: false,
			error: null,
		});
		try {
			await api.setup.resendSmtpVerificationCode();
			setVerificationResendState({
				resending: false,
				resend: true,
				error: null,
			});
			enqueueSnackbar(t('page.setupWizard.actionMessages.verificationCodeSuccessfullyResent'), {
				variant: 'success',
				persist: false,
			});
		} catch (error) {
			setVerificationResendState({
				resending: false,
				resend: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, [verificationResendState]);

	const handleOnBack = React.useCallback(async () => {
		try {
			const response = await api.setup.setBackSetupProgress();
			const step = response.data;

			setIndexPage(getCurrentStep(step));
		} catch (error) {
			setVerificationResendState({
				resending: false,
				resend: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	const hanleOnConfirmBack = React.useCallback(() => {
		setIndexPage(getCurrentStep(ESetupSteps.SMTP));
	}, []);

	const handleOnSubmit = React.useCallback(
		async (formValues: VerificationFormValues) => {
			if (verificationState.verifying) {
				return;
			}
			setVerificationState({
				verifying: true,
				verified: false,
				error: null,
			});
			try {
				await api.setup.verify({
					verificationCode: formValues.verificationCode,
				});
				setVerificationState({
					verifying: false,
					verified: true,
					error: null,
				});
				enqueueSnackbar(t('page.setupWizard.actionMessages.emailSuccessfullyVerified'), {
					variant: 'success',
					persist: false,
				});
				setIndexPage(getCurrentStep(ESetupSteps.IDENTITY_STORE));
			} catch (error) {
				setVerificationState({
					verifying: false,
					verified: false,
					error: error as AxiosError,
				});
				console.error(error);
			}
		},
		[verificationState],
	);

	const handleKeyDown = useKeyPress(WATCHED_KEYS, handleSubmit(handleOnSubmit));

	return (
		<>
			<Box onKeyDown={handleKeyDown}>
				<SEO
					title={t('page.setupWizard.texts.verification.title')}
					description={t('page.setupWizard.texts.verification.description')}
				/>
				<FormGroup
					sx={{
						marginBottom: 2,
						marginTop: 2,
					}}
				>
					<Stack direction='column' sx={{ marginBottom: 2 }}>
						<Typography align='center'>{t('page.setupWizard.texts.verification.text')}</Typography>
						<br />
					</Stack>
					<Grid container spacing={2} display='flex' justifyContent='center'>
						<OneTimePassword
							name='verificationCode'
							control={control}
							error={errors.verificationCode}
							length={6}
						/>
					</Grid>
				</FormGroup>
				<Stack
					direction='column'
					spacing={2}
					sx={{
						marginTop: 2,
					}}
				>
					<Typography align='center'>
						{t('page.setupWizard.texts.verification.resendMessage')}{' '}
						<Link
							onClick={handleOnResendVerificationCode}
							sx={{
								cursor: 'pointer',
							}}
						>
							{t('page.setupWizard.texts.verification.resendLink')}{' '}
						</Link>
					</Typography>
				</Stack>
				<br />
				{device === 'desktop' ?
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Button
							sx={{
								marginRight: device === 'desktop' ? 2 : 0,
							}}
							variant='outlined'
							type='submit'
							onClick={handleOnBack}
						>
							{t('page.setupWizard.texts.verification.back')}
						</Button>
						<Button variant='contained' type='submit' onClick={handleSubmit(handleOnSubmit)}>
							{t('page.setupWizard.texts.verification.next')}
						</Button>
					</Grid>
				:	<Grid
						container
						spacing={2}
						sx={{
							marginTop: 2,
						}}
					>
						<Grid item xs={12}>
							<Button fullWidth variant='outlined' type='submit' onClick={handleOnBack}>
								{t('page.setupWizard.texts.verification.back')}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth variant='contained' type='submit' onClick={handleSubmit(handleOnSubmit)}>
								{t('page.setupWizard.texts.verification.next')}
							</Button>
						</Grid>
					</Grid>
				}
			</Box>
			<ConfirmationDialog
				onClose={handleOnClose}
				open={open}
				onConfirm={hanleOnConfirmBack}
				title={t('page.setupWizard.texts.confirmationDialog.title')}
				text={t('page.setupWizard.texts.confirmationDialog.text')}
				cancelText={t('page.setupWizard.texts.confirmationDialog.cancelText')}
				confirmText={t('page.setupWizard.texts.confirmationDialog.confirmText')}
			/>
		</>
	);
};
