import { z } from 'zod';

import { EPAMTargetType, ERDPSecurityMode, ERevType } from '../../api/Api';
import { TFunction } from 'i18next';

export const userInputListSchema = z.object({
	id: z.number(),
	targetType: z.string(),
	userID: z.number(),
	userName: z.string(),
	userSurname: z.string(),
	accountName: z.string(),
	startAt: z.string(),
	endAt: z.string().nullable(),
	userInput: z.array(
		z.object({
			sessionID: z.number(),
			line: z.string(),
			startAt: z.string(),
			endAt: z.string(),
		}),
	),
});

export const pamTargetListSchema = z.object({
	id: z.number(),
	type: z.string(),
	name: z.string(),
	description: z.string().optional(),
	hostname: z.string(),
	authorizationRequired: z.boolean(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
			})
			.optional(),
	),
});

export const sessionListSchema = z.object({
	id: z.number(),
	targetType: z.string(),
	targetName: z.string(),
	userID: z.number(),
	userName: z.string(),
	userSurname: z.string().optional(),
	accountName: z.string(),
	startAt: z.string(),
	endAt: z.string().optional(),
});

export const userListSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	status: z.string(),
	email: z
		.string()
		.min(1)
		.max(64)
		.refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
});

export const auditLogSchema = z.object({
	createdAt: z.string(),
	modifiedAt: z.string(),
	modifiedBy: z.number(),
	authorizationRequired: z.boolean(),
	useExcaliburCredentials: z.boolean(),
	fileTransferRootDirectory: z.string().nullable(),
	uploadFileSizeLimit: z.number().nullable(),
	fileUploadEnabled: z.boolean(),
	fileDownloadEnabled: z.boolean(),
	clipboardEnablePaste: z.boolean(),
	clipboardEnableCopy: z.boolean(),
	recordingRetentionPeriod: z.number(),
	recordingEnabled: z.boolean(),
	port: z.number(),
	hostname: z.string(),
	description: z.string().nullable(),
	name: z.string(),
	tenantID: z.number().nullable(),
	type: z.nativeEnum(EPAMTargetType),
	revType: z.nativeEnum(ERevType),
	revID: z.number(),
});

export const rdpAuditLogSchema = z.object({
	modifiedBy: z.number(),
	modifiedAt: z.string(),
	remoteApplicationCommandLineArgs: z.string().nullable(),
	remoteApplicationWorkingDir: z.string().nullable(),
	remoteApplicationName: z.string().nullable(),
	securityMode: z.nativeEnum(ERDPSecurityMode),
	ignoreCertificate: z.boolean(),
	targetID: z.number(),
	revType: z.nativeEnum(ERevType),
	revID: z.number(),
});

export const getChangePasswordFormSchema = (t: TFunction) =>
	z
		.object({
			password: z.string().refine((value) => !!value, t('page.pam.list.changePasswordDialog.password.required')),
			passwordAgain: z.string(),
		})
		.refine((data) => data.password === data.passwordAgain, {
			message: t('page.pam.list.changePasswordDialog.passwordAgain.mismatch'),
			path: ['passwordAgain'],
		});
