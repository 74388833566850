import * as React from 'react';
import {
	Box,
	Paper,
	Tooltip,
	FormGroup,
	TextField as OriginalTextField,
	IconButton,
	Stack,
	useTheme,
	useMediaQuery,
	Checkbox,
	Typography,
	FormControlLabel,
} from '@mui/material';
import { Refresh, Save as SaveIcon, ContentCopy as ContentCopyIcon, Token as TokenIcon } from '@mui/icons-material';
import { zodResolver } from '@hookform/resolvers/zod';
import copy from 'copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useNavigate } from '../../hooks/useNavigate';
import { Preloader } from '../../components/Preloader/Preloader';
import { CreateOAuthClientDto } from '../../api/Api';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { FormValues, SubmitFormState, OAuthClientState, RefreshClientSecretState } from './types';
import { getOAuthClientFormSchema } from './schemas';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { Heading } from '../../components/Heading/Heading';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { Textarea } from '../../components/FormFields/Textarea/Textarea';
import { EFieldType } from '../../components/FormFields/TextField/enums';
import { getDurationInUnits, getDurationInString } from '../../utils/DateTime';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';
import { CheckBoxGroup } from './Components/CheckBoxGroup/CheckBoxGroup';

import { OAUTH_CLIENT_TOKEN_DURATION_UNITS } from './constants';

import { CheckBoxItemsGroup } from './types';

export const OAuthClientAddEdit: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [originalSecret, setOriginalSecret] = React.useState<string | null>(null);
	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
	const previousOriginalSecret = usePreviousValue(originalSecret);
	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});
	const [oAuthClientState, setOAuthClientState] = React.useState<OAuthClientState>({
		loading: false,
		loaded: false,
		data: {},
		error: null,
	});
	const [refreshClientSecretState, setRefreshClientSecretState] = React.useState<RefreshClientSecretState>({
		refreshing: false,
		refreshed: false,
		error: null,
	});
	const [permissionGroupsMap, setPermissionGroupMap] = React.useState<Map<number, CheckBoxItemsGroup>>(new Map());

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getOAuthClientFormSchema(t)),
	});

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData) => {
			const requestBody: CreateOAuthClientDto = {
				name: formData.name,
				description: formData.description,
				accessTokenValidity: getDurationInUnits(
					formData.accessTokenValidity,
					OAUTH_CLIENT_TOKEN_DURATION_UNITS,
				),
			};
			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});
				if (id) {
					const tenantPermissionIDsToCreate: number[] = [];
					const systemPermissionIDsToCreate: number[] = [];
					const tenantPermissionIDsToDelete: number[] = [];
					const systemPermissionIDsToDelete: number[] = [];
					const oAuthPermissionsResponse = await api.oauthClient.getPermissionsForOAuthClient(id);
					const initialSystemPermissions = oAuthPermissionsResponse.data.systemPermissions.map(
						(permission) => permission.id,
					);
					const initialTenantPermissions = oAuthPermissionsResponse.data.tenantPermissions.map(
						(permission) => permission.id,
					);

					Array.from(permissionGroupsMap?.values() ?? []).forEach((group) => {
						const groupList = Array.from(group.checkBoxItemMap.values());
						groupList.forEach((item) => {
							if (item.isSystemChecked && !initialSystemPermissions.includes(item.id)) {
								systemPermissionIDsToCreate.push(item.id);
							} else if (!item.isSystemChecked && initialSystemPermissions.includes(item.id)) {
								systemPermissionIDsToDelete.push(item.id);
							}
							if (item.isTenantChecked && !initialTenantPermissions.includes(item.id)) {
								tenantPermissionIDsToCreate.push(item.id);
							} else if (!item.isTenantChecked && initialTenantPermissions.includes(item.id)) {
								tenantPermissionIDsToDelete.push(item.id);
							}
						});
					});
					await api.oauthClient.updateOauthClient(id, requestBody);
					if (systemPermissionIDsToDelete.length > 0 || tenantPermissionIDsToDelete.length > 0) {
						await api.oauthClient.deletePermissionsFromOAuthClient(id, {
							tenantPermissionIDs: tenantPermissionIDsToDelete || [],
							systemPermissionIDs: systemPermissionIDsToDelete || [],
						});
					}
					if (systemPermissionIDsToCreate.length > 0 || tenantPermissionIDsToCreate.length > 0) {
						await api.oauthClient.addPermissionsToOAuthClient(id, {
							tenantPermissionIDs: tenantPermissionIDsToCreate,
							systemPermissionIDs: systemPermissionIDsToCreate,
						});
					}
					enqueueSnackbar(t('page.oAuthClients.edit.actionMessages.clientSecretSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
				} else {
					const selectedPermmissionIDs = Array.from(permissionGroupsMap?.values() ?? [])
						.map((group) =>
							Array.from(group.checkBoxItemMap.values())
								.filter((item) => item.isSystemChecked)
								.map((item) => item.id),
						)
						.flat();
					const selectedTenantPermmissionIDs = Array.from(permissionGroupsMap?.values() ?? [])
						.map((group) =>
							Array.from(group.checkBoxItemMap.values())
								.filter((item) => item.isTenantChecked)
								.map((item) => item.id),
						)
						.flat();
					const createResult = await api.oauthClient.createOauthClient(requestBody);
					if (selectedPermmissionIDs.length > 0 || selectedTenantPermmissionIDs.length > 0) {
						await api.oauthClient.addPermissionsToOAuthClient(createResult.data.clientID as string, {
							tenantPermissionIDs: selectedTenantPermmissionIDs,
							systemPermissionIDs: selectedPermmissionIDs,
						});
					}
					navigate(`/security/oauth/edit/${createResult.data.clientID as string}`);
				}
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error) {
				console.error(error);
				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: error as AxiosError,
				});
			}
		},
		[api.oauthClient, enqueueSnackbar, setSubmitFormState, id, permissionGroupsMap],
	);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const getOAuthClient = async (clientID: string) => {
		setOAuthClientState({
			loading: true,
			loaded: false,
			data: {},
			error: null,
		});
		try {
			const response = await api.oauthClient.getOneByClientId(clientID);
			if (response.data.originalSecret) {
				setOriginalSecret(response.data.originalSecret as string);
			}
			setOAuthClientState({
				loading: false,
				loaded: true,
				data: response.data as Record<string, unknown>,
				error: null,
			});

			reset({
				name: response.data.name,
				description: response.data.description,
				accessTokenValidity:
					response.data.accessTokenValidity ?
						getDurationInString(response.data.accessTokenValidity, OAUTH_CLIENT_TOKEN_DURATION_UNITS)
					:	'',
			});
		} catch (error) {
			setOAuthClientState({
				loading: false,
				loaded: false,
				data: {},
				error: error as AxiosError,
			});
			console.error(error);
		}
	};

	React.useEffect(() => {
		if (id && !oAuthClientState.loaded && !oAuthClientState.loading && !oAuthClientState.error) {
			getOAuthClient(id);
		}
	}, [id, oAuthClientState]);

	const handleOnCopyClientSecretToClipboard = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.preventDefault();
			if (originalSecret) {
				copy(originalSecret);
				enqueueSnackbar(t('page.oAuthClients.edit.clipBoardCopyMessages.clientSecret'), {
					variant: 'success',
					persist: false,
				});
			}
		},
		[originalSecret, t],
	);

	const handleOnCopyClientIDToClipboard = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.preventDefault();
			if (oAuthClientState.data.clientID) {
				copy(oAuthClientState.data.clientID as string);
				enqueueSnackbar(t('page.oAuthClients.edit.clipBoardCopyMessages.clientID'), {
					variant: 'success',
					persist: false,
				});
			}
		},
		[oAuthClientState, t],
	);

	const handleRefreshClientSecret = React.useCallback(async () => {
		if (refreshClientSecretState.refreshing) {
			return;
		}
		setRefreshClientSecretState({
			refreshing: true,
			refreshed: false,
			error: null,
		});
		try {
			const refreshResult = await api.oauthClient.refreshClientSecret(oAuthClientState.data.clientID as string);
			setOriginalSecret(refreshResult.data.originalSecret as string);
			setRefreshClientSecretState({
				refreshing: false,
				refreshed: true,
				error: null,
			});
			enqueueSnackbar(t('page.oAuthClients.edit.actionMessages.clientSecretSuccessfullyRefreshed'), {
				variant: 'success',
				persist: false,
			});
			setOpen(false);
		} catch (error) {
			setRefreshClientSecretState({
				refreshing: false,
				refreshed: false,
				error: error as AxiosError,
			});
			setOpen(false);
			console.error(error);
		}
	}, [t, refreshClientSecretState, oAuthClientState.data.clientID]);

	const handleOnOpenConfirmationDialog = React.useCallback(() => {
		setOpen(true);
	}, []);

	React.useEffect(() => {
		if (
			shouldDisableForm &&
			!submitFormState.submitting &&
			!oAuthClientState.loading &&
			!refreshClientSecretState.refreshing
		) {
			setShouldDisableForm(false);
		} else if (
			!shouldDisableForm &&
			(submitFormState.submitting || oAuthClientState.loading || refreshClientSecretState.refreshing)
		) {
			setShouldDisableForm(true);
		}
	}, [refreshClientSecretState, oAuthClientState, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		if (!previousOriginalSecret && originalSecret && !refreshClientSecretState.refreshed) {
			enqueueSnackbar(t('page.oAuthClients.edit.actionMessages.clientSuccessfullyCreated'), {
				variant: 'success',
				persist: false,
			});
		}
	}, [previousOriginalSecret, originalSecret, refreshClientSecretState]);

	const handleClose = React.useCallback(() => {
		setOpen(false);
	}, []);

	React.useEffect(() => {
		const fetchPermissions = async () => {
			try {
				const query = {
					filter: ['type=oauth'],
				};
				const allPermissionsResponse = await api.permissions.getPermissions(query);
				let selectedPermissions = new Set<number>();
				let selectedTenantPermissions = new Set<number>();
				if (id) {
					const oAuthPermissionsResponse = await api.oauthClient.getPermissionsForOAuthClient(id ?? '');
					selectedPermissions = new Set(
						oAuthPermissionsResponse.data.systemPermissions.map((permission) => permission.id),
					);
					selectedTenantPermissions = new Set(
						oAuthPermissionsResponse.data.tenantPermissions.map((permission) => permission.id),
					);
				}

				setPermissionGroupMap(
					new Map(
						allPermissionsResponse.data.entities.map((permissionGroup) => {
							const checkBoxItemMap = new Map(
								permissionGroup.permissions.map((permissionItem) => [
									permissionItem.id,
									{
										id: permissionItem.id,
										name: permissionItem.title,
										description: permissionItem.description,
										isSystemChecked: selectedPermissions.has(permissionItem.id),
										isTenantChecked: selectedTenantPermissions.has(permissionItem.id),
									},
								]),
							);

							return [
								permissionGroup.id,
								{
									id: permissionGroup.id,
									name: permissionGroup.title,
									description: permissionGroup.description,
									checkBoxItemMap,
								},
							];
						}),
					),
				);
			} catch (error) {
				console.error('Failed to fetch target groups', error);
			}
		};

		fetchPermissions();
	}, [id, api.permissions, setPermissionGroupMap]);

	const permissionGroupsArray = Array.from(permissionGroupsMap.entries());

	const hasAllChecked = () => {
		const groupList = Array.from(permissionGroupsMap.values());

		return groupList.every((group) => {
			const groupItemList = Array.from(group.checkBoxItemMap.values());

			return groupItemList.every((item) => {
				if (group.id === 6) {
					return item.isSystemChecked;
				}

				return item.isSystemChecked && item.isTenantChecked;
			});
		});
	};

	const hasNoChecked = () => {
		const groupList = Array.from(permissionGroupsMap.values());

		return groupList.every((group) => {
			const groupItemList = Array.from(group.checkBoxItemMap.values());

			return groupItemList.every((item) => !item.isSystemChecked && !item.isTenantChecked);
		});
	};

	const hasPartialChecked = () => {
		const allChecked = hasAllChecked();
		const noneChecked = hasNoChecked();

		return !allChecked && !noneChecked;
	};

	const unCheckAll = () => {
		const groupList = Array.from(permissionGroupsMap.values());
		groupList.forEach((group) => {
			const groupItemList = Array.from(group.checkBoxItemMap.values());
			groupItemList.forEach((item) => {
				group.checkBoxItemMap.set(item.id, {
					...item,
					isSystemChecked: false,
					isTenantChecked: false,
				});
			});
			permissionGroupsMap.set(group.id, group);
		});
		setPermissionGroupMap(new Map(permissionGroupsMap));
	};

	const checkAll = () => {
		const groupList = Array.from(permissionGroupsMap.values());
		groupList.forEach((group) => {
			const groupItemList = Array.from(group.checkBoxItemMap.values());
			groupItemList.forEach((item) => {
				group.checkBoxItemMap.set(item.id, {
					...item,
					isSystemChecked: true,
					// eslint-disable-next-line no-unneeded-ternary
					isTenantChecked: group.id === 6 ? false : true,
				});
			});
			permissionGroupsMap.set(group.id, group);
		});
		setPermissionGroupMap(new Map(permissionGroupsMap));
	};

	const renderCheckAllControl = () => {
		const allChecked = hasAllChecked();
		const partialChecked = hasPartialChecked();

		return (
			<Checkbox
				checked={allChecked}
				indeterminate={partialChecked}
				onClick={() => {
					if (allChecked || partialChecked) {
						unCheckAll();
					} else {
						checkAll();
					}
				}}
			/>
		);
	};

	return (
		<Box component={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
			{id && (oAuthClientState.loading || !oAuthClientState.loaded) ?
				<Preloader />
			:	<Paper elevation={3}>
					<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
						<PageHeader
							title={id ? t('page.oAuthClients.edit.title') : t('page.oAuthClients.add.title')}
							description={
								id ? t('page.oAuthClients.edit.description') : t('page.oAuthClients.add.description')
							}
							icon={TokenIcon}
						/>
					</Box>
					<FormGroup
						sx={{
							padding: 2,
						}}
					>
						<Heading label={t('page.oAuthClients.edit.subtitle.general')} />
						<Stack
							spacing={2}
							sx={{
								width: getStackWidth(),
							}}
						>
							{oAuthClientState.data.clientID ?
								<OriginalTextField
									label={t('page.oAuthClients.edit.form.clientID.label')}
									variant='outlined'
									fullWidth
									disabled
									value={oAuthClientState.data.clientID as string}
									helperText={t('page.oAuthClients.edit.form.clientID.helperText')}
									InputProps={{
										readOnly: true,
										endAdornment: (
											<Tooltip
												title={t('page.oAuthClients.edit.tooltips.clientIDTooltip')}
												enterDelay={500}
												arrow
											>
												<IconButton aria-label='copy' onClick={handleOnCopyClientIDToClipboard}>
													<ContentCopyIcon />
												</IconButton>
											</Tooltip>
										),
									}}
								/>
							:	null}
							{id ?
								<OriginalTextField
									label={t('page.oAuthClients.edit.form.clientSecret.label')}
									variant='outlined'
									fullWidth
									disabled
									value={originalSecret ? originalSecret : '******************'}
									helperText={
										originalSecret ?
											t('page.oAuthClients.edit.form.clientSecret.helperTextVisible')
										:	t('page.oAuthClients.edit.form.clientSecret.helperTextHidden')
									}
									InputProps={{
										readOnly: true,
										endAdornment: (
											<>
												{originalSecret ?
													<Tooltip
														title={t('page.oAuthClients.edit.tooltips.clientSecretTooltip')}
														arrow
													>
														<IconButton
															aria-label='copy'
															onClick={handleOnCopyClientSecretToClipboard}
														>
															<ContentCopyIcon />
														</IconButton>
													</Tooltip>
												:	<Tooltip
														title={t(
															'page.oAuthClients.edit.clipBoardCopyMessages.refreshClientSecret',
														)}
														arrow
													>
														<IconButton
															aria-label='refresh'
															onClick={handleOnOpenConfirmationDialog}
														>
															<Refresh />
														</IconButton>
													</Tooltip>
												}
											</>
										),
									}}
								/>
							:	null}

							<TextField
								name={'name'}
								register={register}
								label={t('page.oAuthClients.edit.form.name.label')}
								error={errors.name}
								disabled={shouldDisableForm}
								helperText={t('page.oAuthClients.edit.form.name.helperText')}
							/>

							<Textarea
								name={'description'}
								register={register}
								label={t('page.oAuthClients.edit.form.description.label')}
								error={errors.description}
								disabled={shouldDisableForm}
								helperText={t('page.oAuthClients.edit.form.description.helperText')}
							/>

							<TextField
								name={'accessTokenValidity'}
								register={register}
								label={t('page.oAuthClients.edit.form.accessTokenValidity.label')}
								error={errors.accessTokenValidity}
								disabled={shouldDisableForm}
								type={EFieldType.TEXT}
								helperText={t('page.oAuthClients.edit.form.accessTokenValidity.helperText')}
							/>

							<Box paddingTop='8px'>
								<Box display='flex' flexDirection='column' width='100%' paddingBottom='16px'>
									<Box
										display='flex'
										justifyContent='space-between'
										alignItems='center'
										justifyItems='center'
									>
										<Typography variant='h6'>{t('component.checkBoxGroupList.name')}</Typography>
									</Box>
									<Box
										display='flex'
										flexDirection='row'
										width='100%'
										alignContent='center'
										alignItems='center'
										justifyContent='space-between'
										paddingRight='16px'
									>
										<Typography>{t('component.checkBoxGroupList.description')}</Typography>
										<FormControlLabel
											onClick={() => {}}
											control={renderCheckAllControl()}
											label={t('component.checkBoxGroupList.selectAll')}
											labelPlacement='start'
											slotProps={{ typography: { variant: 'body2' } }}
										/>
									</Box>
								</Box>
								{permissionGroupsArray.map(([key, group]) => {
									return (
										<Box key={group.id}>
											<Box
												display='flex'
												flexDirection='column'
												width='100%'
												paddingBottom='16px'
											></Box>
											<Box display='flex' flexDirection='column' gap='8px'>
												<CheckBoxGroup
													group={group}
													setGroups={setPermissionGroupMap}
													groups={permissionGroupsMap}
												/>
											</Box>
										</Box>
									);
								})}
							</Box>
						</Stack>
					</FormGroup>

					<FloatingButtonSave
						type='submit'
						disabled={shouldDisableForm}
						ariaLabel={t('page.oAuthClients.edit.ariaLabel.save')}
						tooltipTitle={
							id ? t('page.oAuthClients.edit.tooltips.save') : t('page.oAuthClients.edit.tooltips.create')
						}
					/>
				</Paper>
			}
			<ConfirmationDialog
				onClose={handleClose}
				open={open}
				onConfirm={handleRefreshClientSecret}
				title={t('page.oAuthClients.edit.confirmation.title')}
				text={t('page.oAuthClients.edit.confirmation.text')}
				cancelText={t('page.oAuthClients.edit.confirmation.cancel')}
				confirmText={t('page.oAuthClients.edit.confirmation.confirm')}
			/>
		</Box>
	);
};
