import { useInfiniteQuery } from '@tanstack/react-query';

import { userListSchema } from './schema';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';

const LIMIT = 10;

export function useFetchUserFilterOptions() {
	const api = useSwaggerApi();

	return useInfiniteQuery({
		queryKey: [EQueryKey.USER_DETAIL_LIST_QUERY],
		queryFn: async ({ pageParam = 0 }) => {
			const query = { limit: LIMIT, offset: pageParam as number };

			const { data } = await api.users.getUserDetails(query);

			return data;
		},
		initialPageParam: 0,
		getNextPageParam: (lastPage, allPages) => {
			const entities = allPages.flatMap((p) => p.entities);
			if (entities.length <= lastPage.total - 1) {
				return allPages.length * LIMIT;
			}

			return;
		},
		select: (data) => {
			const entitiesFlat = data.pages.flatMap((p) => p.entities);

			for (const user of entitiesFlat) {
				userListSchema.parse(user);
			}

			return entitiesFlat.map((user) => ({
				label: user.surname ? `${user.name} ${user.surname}` : user.name,
				id: user.id,
			}));
		},
	});
}
