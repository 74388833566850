import React, { useEffect, useMemo, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { closestTo } from 'date-fns';
import { MRT_SortingState } from 'material-react-table';
import { useTranslation } from 'react-i18next';

import { AuditLogTimeline } from '../../components/AuditLogTimeline/AuditLogTimeline';
import { AuditLogData } from '../../components/AuditLogTimeline/types';
import { AuditLogsSectionProps } from './types';
import { EPAMTargetType } from '../../api/Api';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { RECORDING_RETENTION_PERIOD_UNITS } from './constants';
import { auditLogSchema, rdpAuditLogSchema } from './schema';
import { convertBytesToMB } from '../../utils/FormatSize';
import { getDurationInString } from '../../utils/DateTime';
import { omitUnusedAuditLogFields } from './helpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useTableQuery } from '../../hooks/useTableQuery';

export const AuditLogsSection: React.FC<AuditLogsSectionProps> = ({ id }) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const { t } = useTranslation();

	const api = useSwaggerApi();

	const defaultSorting: MRT_SortingState = [{ desc: true, id: 'modifiedAt' }];

	const {
		columnFilters,
		setColumnFilters,
		swaggerQuery: { filter, sort },
	} = useTableQuery([], { defaultSorting });

	useEffect(() => {
		setPage(0);
	}, [columnFilters]);

	const { data: auditLogs, isLoading: isLoadingAuditLogs } = useQuery({
		queryKey: [EQueryKey.PAM_TARGET_AUDIT_LOG_LIST_QUERY, id, page, rowsPerPage, filter, sort],
		queryFn: async () => {
			const { data } = await api.pam.getPamTargetAuditCollection(id, {
				filter,
				sort,
				limit: rowsPerPage,
				offset: page,
			});

			return data;
		},
		select: (data) => {
			for (const entity of data.entities) {
				auditLogSchema.parse(entity);
			}

			return data;
		},
	});

	const { entities: auditLogEntities = [], total = 0 } = auditLogs ?? {};

	const targetType = useMemo(() => {
		if (auditLogEntities.length === 0) {
			return null;
		}

		return auditLogEntities[0].type;
	}, [auditLogs]);

	const userIDs = useMemo(() => {
		if (!auditLogs) {
			return [];
		}

		return auditLogEntities.map((auditLog) => auditLog.modifiedBy).filter((userId) => !!userId) as number[];
	}, [auditLogs]);

	const userQueries = useQueries({
		queries: userIDs.map((id) => ({
			queryKey: [EQueryKey.USER_DETAIL_QUERY, id],
			queryFn: async () => {
				try {
					const { data } = await api.users.getUserDetail(id);

					return { ...data, id };
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error(error);
				}
			},
		})),
	});

	const isLoadingUsers = userQueries.some((query) => query.isLoading);

	const users = useMemo(() => {
		if (isLoadingUsers) {
			return [];
		}

		return userQueries.map((query) => query.data).filter((data) => data !== undefined);
	}, [isLoadingUsers, userQueries]);

	const { data: rdpAuditLogs, isLoading: isLoadingRDPAuditLogs } = useQuery({
		queryKey: [EQueryKey.PAM_TARGET_RDP_AUDIT_LOG_LIST_QUERY, id, page, rowsPerPage, filter, sort],
		queryFn: async () => {
			const { data } = await api.pam.getPamrdpTargetAuditCollection(id, {
				filter,
				sort,
				limit: rowsPerPage,
				offset: page,
			});

			return data;
		},
		enabled: targetType === EPAMTargetType.RDP,
		select: (data) => {
			for (const entity of data.entities) {
				rdpAuditLogSchema.parse(entity);
			}

			return data;
		},
	});

	const { entities: rdpAuditLogEntities = [] } = rdpAuditLogs ?? {};

	const filteredAuditLogs = useMemo(() => {
		if (!auditLogs || (targetType === EPAMTargetType.RDP && !rdpAuditLogs)) {
			return [];
		}

		return auditLogEntities.map((auditLog) => {
			const auditLogFields = omitUnusedAuditLogFields(auditLog);

			const modifiedByUser = users.find((user) => user?.id === auditLogFields.modifiedBy);

			let modifiedBy = '';
			if (modifiedByUser) {
				modifiedBy =
					modifiedByUser.surname ? `${modifiedByUser.name} ${modifiedByUser.surname}` : modifiedByUser.name;
			}

			const uploadFileSizeLimit =
				typeof auditLogFields.uploadFileSizeLimit === 'number' ?
					`${convertBytesToMB(auditLogFields.uploadFileSizeLimit)} MB`
				:	undefined;

			const recordingRetentionPeriod =
				typeof auditLogFields.recordingRetentionPeriod === 'number' ?
					getDurationInString(auditLogFields.recordingRetentionPeriod, RECORDING_RETENTION_PERIOD_UNITS)
				:	undefined;

			const auditLogTimelineData = {
				...auditLogFields,
				modifiedBy,
				recordingRetentionPeriod,
				uploadFileSizeLimit,
			};

			if (targetType === EPAMTargetType.RDP && rdpAuditLogEntities.length > 0) {
				const closestTimestamp = closestTo(
					auditLog.modifiedAt,
					rdpAuditLogEntities.map((rdpAuditLog) => new Date(rdpAuditLog.modifiedAt)),
				);
				if (closestTimestamp) {
					const rdpAuditLog = rdpAuditLogEntities.find(
						(rdpAuditLog) => new Date(rdpAuditLog.modifiedAt).getTime() === closestTimestamp.getTime(),
					);

					if (rdpAuditLog) {
						const {
							ignoreCertificate,
							securityMode,
							remoteApplicationCommandLineArgs,
							remoteApplicationName,
							remoteApplicationWorkingDir,
						} = rdpAuditLog;

						return {
							...auditLogTimelineData,
							ignoreCertificate,
							securityMode,
							remoteApplicationCommandLineArgs,
							remoteApplicationName,
							remoteApplicationWorkingDir,
						} as AuditLogData;
					}
				}
			}

			return auditLogTimelineData;
		});
	}, [auditLogs, rdpAuditLogs, targetType, users]);

	return (
		<AuditLogTimeline
			data={filteredAuditLogs}
			isLoading={
				isLoadingAuditLogs || isLoadingUsers || (targetType === EPAMTargetType.RDP && isLoadingRDPAuditLogs)
			}
			total={total}
			filters={columnFilters}
			page={page}
			rowsPerPage={rowsPerPage}
			setFilters={setColumnFilters}
			setPage={setPage}
			setRowsPerPage={setRowsPerPage}
			translateFieldKey={(fieldKey) => t(`page.pam.detail.auditLogs.fields.${fieldKey}`)}
		/>
	);
};
