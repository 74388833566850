import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MaterialReactTable,
} from 'material-react-table';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Alert, Avatar, Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
	GetAppOutlined as GetAppOutlinedIcon,
	PlayArrow as PlayArrowIcon,
	Download as DownloadIcon,
	Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GeneralSectionProps } from './types';
import {
	EPAMTargetType,
	GetPAMRDPTargetResponseDto,
	GetPAMSSHTargetResponseDto,
	GetPAMTargetSessionsResponseDto,
	GetPAMTargetUsersResponseDto,
	PAMTargetGroupModel,
	PamSessionDto,
	UserDetailModel,
} from '../../api/Api';
import { useTableQuery } from '../../hooks/useTableQuery';
import { useNavigate } from '../../hooks/useNavigate';
import { useFormatDate } from '../../hooks/useFormatDate';
import { calculateDuration, formatDurationToString } from '../../utils/SessionDateHelpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';

import { useACL } from '../../hooks/useACL';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { sessionListSchema, userListSchema } from './schema';
import { EPermission } from '../../enums/permission/EPermission';
import { ESessionTarget } from '../../enums/session/ESessionTarget';
import { SessionPlayerDialog } from '../../components/Dialog/SessionPlayerDialog/SessionPlayerDialog';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { getSessionTargetLogo } from '../sessions/utils';
import { SectionConfig } from '../../components/DetailSection/types';
import { DetailSection } from '../../components/DetailSection/DetailSection';
import { LabeledBox } from '../../components/LabeledBox/LabeledBox';
import { Link } from '../../components/Link/Link';
import { FloatingButtonEdit } from '../../components/Buttons/FloatingButton/FloatingButtonEdit';
import { Preloader } from '../../components/Preloader/Preloader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { convertBytesToMB } from '../../utils/FormatSize';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';
import { formatDateTime, getDurationInString } from '../../utils/DateTime';
import { FETCH_INTERVAL, RECORDING_RETENTION_PERIOD_UNITS } from './constants';
import { usePathName } from '../../hooks/usePathName';

const StyledSpan = styled.span`
	cursor: pointer;
	font-weight: bold;

	:hover {
		text-decoration: underline;
	}
`;

export const GeneralSection: React.FC<GeneralSectionProps> = ({
	id,
	auditData,
	auditDateTime,
	groups,
	isAudit,
	targetType,
}) => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const reactQueryClient = useReactQueryClient();
	const pathname = usePathName();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const { t } = useTranslation();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [hasRunningSession, setHasRunningSession] = React.useState(false);
	const [openPlayerDialog, setOpenPlayerDialog] = React.useState(false);
	const [recordingUrl, setRecordingUrl] = React.useState('');
	const [durationIncrement, setDurationIncrement] = React.useState(0);

	// Has to be fetched always because response contains isAudit flag
	const { data: currentData, isLoading: isLoadingCurrentData } = useQuery<
		GetPAMSSHTargetResponseDto | GetPAMRDPTargetResponseDto | undefined
	>({
		queryKey: [EQueryKey.PAM_TARGET_DETAILs_QUERY, id],
		queryFn: async () => {
			try {
				if (targetType === EPAMTargetType.SSH) {
					const { data } = await api.pam.getSshTarget(id);

					return data;
				}

				if (targetType === EPAMTargetType.RDP) {
					const { data } = await api.pam.getRdpTarget(id);

					return data;
				}

				throw new Error(t('page.pam.detail.errorMessages.unsupportedType', { type: targetType }));
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
	});

	const { data: auditRDPTargetDetails, isLoading: isLoadingAuditRDPTargetDetails } = useQuery({
		queryKey: [EQueryKey.PAM_TARGET_RDP_DETAILS_AUDIT_QUERY, id, auditDateTime],
		queryFn: async () => {
			try {
				const { data } = await api.pam.getPamrdpTargetAuditCollection(id, {
					filter: [`<=(modifiedAt,${auditDateTime})`],
					sort: ['-modifiedAt'],
					limit: 1,
					offset: 0,
				});

				if (data.entities.length === 0) {
					throw new Error(t('page.pam.detail.errorMessages.noDataFound'));
				}

				return data.entities[0];
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		enabled: isAudit && targetType === EPAMTargetType.RDP,
	});

	const {
		columnFilters: columnFiltersSession,
		setColumnFilters: setColumnFiltersSession,
		sorting: sortingSession,
		setSorting: setSortingSession,
		columnVisibility: columnVisibilitySession,
		setColumnVisibility: setColumnVisibilitySession,
		globalFilter: globalFilterSession,
		setGlobalFilter: setGlobalFilterSession,
		pagination: paginationSession,
		setPagination: setPaginationSession,
		swaggerQuery: swaggerQuerySession,
	} = useTableQuery(['userFullName', 'accountName', 'startAt', 'endAt']);

	const {
		columnFilters: columnFiltersUser,
		setColumnFilters: setColumnFiltersUser,
		sorting: sortingUser,
		setSorting: setSortingUser,
		columnVisibility: columnVisibilityUser,
		setColumnVisibility: setColumnVisibilityUser,
		globalFilter: globalFilterUser,
		setGlobalFilter: setGlobalFilterUser,
		pagination: paginationUser,
		setPagination: setPaginationUser,
		swaggerQuery: swaggerQueryUser,
	} = useTableQuery(['userFullName', 'email']);

	const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

	const {
		data: sessionList,
		isRefetching: sessionListIsRefetching,
		isLoading: sessionListIsLoading,
		error: sessionListError,
	} = useQuery<GetPAMTargetSessionsResponseDto>({
		queryKey: [EQueryKey.PAM_TARGET_SESSION_LIST_QUERY, swaggerQuerySession, id],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuerySession.limit,
					offset: swaggerQuerySession.offset,
					columns: swaggerQuerySession.columns,
					filter: swaggerQuerySession.filter,
					sort: swaggerQuerySession.sort,
				};

				const response = await api.pam.getPamTargetSessions(id, query);

				response.data.entities.forEach((session) => {
					sessionListSchema.parse(session);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		enabled: !isAudit,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities: sessionEntities = [], total: sessionTotal = 0 } = sessionList ? sessionList : {};

	const {
		data: userList,
		isRefetching: userListIsRefetching,
		isLoading: userListIsLoading,
		error: userListError,
	} = useQuery<GetPAMTargetUsersResponseDto>({
		queryKey: [EQueryKey.PAM_TARGET_USER_LIST_QUERY, swaggerQueryUser, id],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQueryUser.limit,
					offset: swaggerQueryUser.offset,
					columns: swaggerQueryUser.columns,
					filter: swaggerQueryUser.filter,
					sort: swaggerQueryUser.sort,
				};

				const response = await api.pam.getPamTargetUsers(id, query);

				response.data.entities.forEach((group) => {
					userListSchema.parse(group);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		enabled: !isAudit,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities: userEntities = [], total: userTotal = 0 } = userList ? userList : {};

	const handleOnTargetGroupChipClick = React.useCallback(
		(group: PAMTargetGroupModel) => {
			if (!isAllowed([EPermission.PAM_GROUPS_READ])) {
				return;
			}
			if (id === group.id) {
				return;
			}
			navigate(`/pam/groups/${group.id}`);
		},
		[isAllowed],
	);

	const renderDuration = React.useMemo(() => {
		const renderSessionDuration = (startAt: string | undefined, endAt: string | undefined | null): string => {
			const duration = calculateDuration(startAt, endAt, durationIncrement);
			const durationString = formatDurationToString(duration);

			return durationString;
		};

		return renderSessionDuration;
	}, [durationIncrement]);

	const getTypescriptUrl = React.useMemo(
		() =>
			(id: number | undefined): string => {
				if (!id) {
					return '';
				}

				return `/api/v1/pam/sessions/${id}/typescript`;
			},
		[],
	);

	const handlePreview = React.useCallback(
		(sessionID: number | undefined) => (event: React.MouseEvent) => {
			//TODO: Handle Session Preview
			event.stopPropagation();
		},
		[],
	);

	const handlePlay = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();
			if (!sessionPath) {
				return;
			}

			setRecordingUrl(sessionPath);
			setOpenPlayerDialog(true);
		},
		[],
	);

	const handleDownload = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();

			if (!sessionPath || !isAllowed([EPermission.PAM_RECORDINGS_READ])) {
				return;
			}

			try {
				const link = document.createElement('a');

				link.href = sessionPath;
				link.setAttribute('download', 'true');

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
			} catch (error) {
				console.error(error);
			}
		},
		[isAllowed],
	);

	const handleOnClose = React.useCallback(() => {
		setOpenPlayerDialog(false);
	}, []);

	const checkHasRunningSession = React.useCallback((sessions: PamSessionDto[]): boolean => {
		if (sessions.length === 0) {
			return false;
		}

		let refreshNeeded = false;

		sessions.forEach((session) => {
			if (!session.endAt) {
				refreshNeeded = true;
			}
		});

		setHasRunningSession(refreshNeeded);

		return refreshNeeded;
	}, []);

	const handleRedirect = React.useCallback(
		(path: string) => (event: React.MouseEvent) => {
			event.stopPropagation();
			navigate(path);
		},
		[],
	);

	React.useEffect(() => {
		if (!sessionEntities) {
			return;
		}

		checkHasRunningSession(sessionEntities);
	}, [sessionEntities]);

	React.useEffect(() => {
		if (!sessionList) {
			return;
		}

		const handleDurationIncrement = () => {
			if (!hasRunningSession) {
				return;
			}
			intervalRef.current = setInterval(() => {
				setDurationIncrement((prevValue) => prevValue + 1000);
			}, 1000);
		};

		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		if (!intervalRef.current && durationIncrement === 0) {
			handleDurationIncrement();
		}
	}, [hasRunningSession]);

	React.useEffect(() => {
		if (!durationIncrement) {
			return;
		}

		if (durationIncrement > FETCH_INTERVAL) {
			reactQueryClient.invalidateQueries();
			setDurationIncrement(0);
		}
	}, [durationIncrement]);

	const sections: SectionConfig[] = React.useMemo(() => {
		if (!currentData && !auditData) {
			return [];
		}

		const allSections: SectionConfig[] = [
			{
				title: t('page.pam.detail.subtitle.general'),
				fields: [
					{
						label: `${t('page.pam.detail.body.target')}: `,
						value: (
							<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
								<Tooltip title={targetType} enterDelay={500} placement='top' arrow>
									<img
										alt='Logo'
										height={28}
										src={getSessionTargetLogo(targetType)}
										loading='lazy'
										style={{ borderRadius: '4px' }}
									/>
								</Tooltip>
								<Typography>{auditData?.name ?? currentData?.name}</Typography>
							</Box>
						),
					},
					...(auditData?.description || currentData?.description ?
						[
							{
								label: `${t('page.pam.detail.body.description')}: `,
								value: auditData?.description ?? currentData?.description,
							},
						]
					:	[]),
				],
			},
			{
				title: t('page.pam.detail.subtitle.network'),
				fields: [
					{
						label: `${t('page.pam.detail.body.hostname')}: `,
						value: auditData?.hostname ?? currentData?.hostname,
					},
					{
						label: `${t('page.pam.detail.body.port')}: `,
						value: auditData?.port ?? currentData?.port,
					},
				],
			},
		];

		if (currentData) {
			allSections.push({
				title: t('page.pam.detail.subtitle.credentials'),
				fields: [
					targetType === EPAMTargetType.RDP && 'domain' in currentData ?
						{
							label: `${t('page.pam.detail.body.domain')}: `,
							value: currentData.domain,
						}
					:	null,
					{
						label: `${t('page.pam.detail.body.username')}: `,
						value: currentData.username,
					},
				],
			});
		}

		if (targetType === EPAMTargetType.RDP) {
			allSections.push(
				{
					title: t('page.pam.detail.subtitle.remoteApp'),
					fields:
						auditRDPTargetDetails ?
							[
								{
									label: `${t('page.pam.detail.body.appName')}: `,
									value: auditRDPTargetDetails?.remoteApplicationName,
								},
								{
									label: `${t('page.pam.detail.body.workingDir')}: `,
									value: auditRDPTargetDetails?.remoteApplicationWorkingDir,
								},
								{
									label: `${t('page.pam.detail.body.commandLineArgs')}: `,
									value: auditRDPTargetDetails?.remoteApplicationCommandLineArgs,
								},
							]
						: currentData ?
							[
								{
									label: `${t('page.pam.detail.body.appName')}: `,
									value: 'remoteApplication' in currentData && currentData.remoteApplication?.name,
								},
								{
									label: `${t('page.pam.detail.body.workingDir')}: `,
									value:
										'remoteApplication' in currentData &&
										currentData.remoteApplication?.workingDirectory,
								},
								{
									label: `${t('page.pam.detail.body.commandLineArgs')}: `,
									value:
										'remoteApplication' in currentData &&
										currentData.remoteApplication?.commandLineArguments,
								},
							]
						:	[],
				},
				{
					title: t('page.pam.detail.subtitle.security'),
					fields:
						auditRDPTargetDetails ?
							[
								{
									label: `${t('page.pam.detail.body.securityMode')}: `,
									value: auditRDPTargetDetails.securityMode,
								},
								{
									label: `${t('page.pam.detail.body.ignoreCertificate')}: `,
									value:
										auditRDPTargetDetails.ignoreCertificate ?
											t('page.pam.detail.body.yes')
										:	t('page.pam.detail.body.no'),
								},
							]
						: currentData ?
							[
								{
									label: `${t('page.pam.detail.body.securityMode')}: `,
									value: 'security' in currentData && currentData.security,
								},
								{
									label: `${t('page.pam.detail.body.ignoreCertificate')}: `,
									value:
										'ignoreCertificate' in currentData && currentData.ignoreCertificate ?
											t('page.pam.detail.body.yes')
										:	t('page.pam.detail.body.no'),
								},
							]
						:	[],
				},
			);
		}

		allSections.push(
			{
				title: t('page.pam.detail.subtitle.sftp'),
				fields:
					auditData ?
						[
							{
								label: `${t('page.pam.detail.body.enableDownload')}: `,
								value:
									auditData.fileDownloadEnabled ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							{
								label: `${t('page.pam.detail.body.enableUpload')}: `,
								value:
									auditData.fileUploadEnabled ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							auditData.uploadFileSizeLimit ?
								{
									label: `${t('page.pam.detail.body.uploadFileSizeLimit')}: `,
									value: `${convertBytesToMB(auditData.uploadFileSizeLimit)} MB`,
								}
							:	null,
							auditData.fileTransferRootDirectory ?
								{
									label: `${t('page.pam.detail.body.fileBrowserRootDir')}: `,
									value: auditData?.fileTransferRootDirectory,
								}
							:	null,
						]
					: currentData ?
						[
							{
								label: `${t('page.pam.detail.body.enableDownload')}: `,
								value:
									currentData.fileTransfer.downloadEnabled ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							{
								label: `${t('page.pam.detail.body.enableUpload')}: `,
								value:
									currentData.fileTransfer.uploadEnabled ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							currentData.fileTransfer.uploadEnabled && currentData.fileTransfer.uploadFileSizeLimit ?
								{
									label: `${t('page.pam.detail.body.uploadFileSizeLimit')}: `,
									value: `${convertBytesToMB(currentData.fileTransfer.uploadFileSizeLimit)} MB`,
								}
							:	null,
							currentData.fileTransfer.rootDirectory ?
								{
									label: `${t('page.pam.detail.body.fileBrowserRootDir')}: `,
									value: currentData.fileTransfer.rootDirectory,
								}
							:	null,
						]
					:	[],
			},
			{
				title: t('page.pam.detail.subtitle.recording'),
				fields:
					auditData ?
						[
							{
								label: `${t('page.pam.detail.body.enableRecording')}: `,
								value:
									auditData.recordingEnabled ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							{
								label: `${t('page.pam.detail.body.retentionPeriod')}: `,
								value:
									auditData.recordingRetentionPeriod ?
										getDurationInString(
											auditData.recordingRetentionPeriod,
											RECORDING_RETENTION_PERIOD_UNITS,
										)
									:	'',
							},
						]
					: currentData ?
						[
							{
								label: `${t('page.pam.detail.body.enableRecording')}: `,
								value:
									currentData.recording.enabled ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							{
								label: `${t('page.pam.detail.body.retentionPeriod')}: `,
								value:
									currentData.recording.retentionPeriod ?
										getDurationInString(
											currentData.recording.retentionPeriod,
											RECORDING_RETENTION_PERIOD_UNITS,
										)
									:	'',
							},
						]
					:	[],
			},
			{
				title: t('page.pam.detail.subtitle.clipboard'),
				fields:
					auditData ?
						[
							{
								label: `${t('page.pam.detail.body.enableCopy')}: `,
								value:
									auditData.clipboardEnableCopy ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							{
								label: `${t('page.pam.detail.body.enablePaste')}: `,
								value:
									auditData.clipboardEnablePaste ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
						]
					: currentData ?
						[
							{
								label: `${t('page.pam.detail.body.enableCopy')}: `,
								value:
									currentData.clipboard.enableCopy ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
							{
								label: `${t('page.pam.detail.body.enablePaste')}: `,
								value:
									currentData.clipboard.enablePaste ?
										t('page.pam.detail.body.yes')
									:	t('page.pam.detail.body.no'),
							},
						]
					:	[],
			},
		);

		return allSections;
	}, [auditData, currentData, targetType, t]);

	const columnsSessionList = React.useMemo<MRTColumnDef<Partial<PamSessionDto>>[]>(
		() => [
			{
				accessorFn: (user) => `${user.userName} ${user.userSurname ? user.userSurname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.pam.detail.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${renderedCellValue}'s avatar.`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'accountName',
				header: t('page.pam.detail.table.header.account'),
			},
			{
				accessorFn: (row) => `${formatDate(row.startAt, true)}`,
				accessorKey: 'startAt',
				filterVariant: 'datetime-range',
				header: t('page.pam.detail.table.header.startAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.endAt ?? undefined, true)}`,
				accessorKey: 'endAt',
				filterVariant: 'datetime-range',
				header: t('page.pam.detail.table.header.endAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'duration',
				header: t('page.pam.detail.table.header.duration'),
				enableSorting: false,
				enableColumnFilter: false,
				enableGlobalFilter: false,
				Cell: ({ row }) => <Box>{renderDuration(row.original.startAt, row.original.endAt)}</Box>,
			},
		],
		[],
	);

	const columnsUserList = React.useMemo<MRTColumnDef<Partial<UserDetailModel>>[]>(
		() => [
			{
				accessorFn: (user) =>
					`${user.title ? `${user.title} ` : ''}${user.name} ${user.surname ? user.surname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.pam.detail.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${renderedCellValue}'s avatar.`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.email}`,
				accessorKey: 'email',
				header: t('page.pam.detail.table.header.email'),
			},
		],
		[],
	);

	const isDeleted = currentData?.isAudit;

	return (
		<>
			{isLoadingCurrentData || (isAudit && targetType === EPAMTargetType.RDP && isLoadingAuditRDPTargetDetails) ?
				<Preloader />
			:	<Stack spacing={2}>
					{isAudit && !isDeleted && auditData && (
						<Alert severity='warning' sx={{ alignItems: 'center' }}>
							{t('page.pam.detail.notice.audit', {
								dateTime: formatDateTime(new Date(auditData.modifiedAt), true),
							})}{' '}
							<StyledSpan
								onClick={() => navigate(pathname, { replace: true, state: { auditDateTime: null } })}
							>
								{t('page.pam.detail.notice.here')}
							</StyledSpan>
							{'.'}
						</Alert>
					)}
					{isDeleted && (
						<Alert severity='error' sx={{ alignItems: 'center' }}>
							{isAudit && auditData ?
								<>
									{t('page.pam.detail.notice.auditDeleted', {
										dateTime: formatDateTime(new Date(auditData.modifiedAt), true),
									})}{' '}
									<StyledSpan
										onClick={() =>
											navigate(pathname, { replace: true, state: { auditDateTime: null } })
										}
									>
										{t('page.pam.detail.notice.here')}
									</StyledSpan>
									{'.'}
								</>
							:	t('page.pam.detail.notice.deleted', {
									dateTime: formatDateTime(new Date(currentData.auditDate as string), true),
								})
							}
						</Alert>
					)}
					<Stack spacing={4}>
						<SectionHeader
							title={t('page.pam.detail.sections.general.title')}
							description={t('page.pam.detail.sections.general.description')}
						/>
						<Stack spacing={2}>
							<DetailSection sections={sections} responsive={true} />

							{!isAudit && (
								<>
									<LabeledBox label={t('page.pam.detail.body.targetGroups')} sx={{ padding: 2 }}>
										<ChipArray
											chipList={isAllowed([EPermission.PAM_GROUPS_READ]) ? groups : undefined}
											onChipClick={handleOnTargetGroupChipClick}
											wrap={true}
										/>
									</LabeledBox>

									<Stack spacing={2}>
										<SectionHeader
											title={t('page.pam.detail.subtitle.sessionList')}
											description={t('page.pam.detail.table.description.sessionList')}
										/>
										<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
											<MaterialReactTable
												columns={columnsSessionList}
												data={sessionEntities}
												enableRowActions
												enableStickyHeader={false}
												state={{
													isLoading: sessionListIsLoading,
													showAlertBanner: sessionListError !== null,
													pagination: paginationSession,
													showProgressBars: sessionListIsRefetching,
													columnFilters: columnFiltersSession,
													globalFilter: globalFilterSession,
													sorting: sortingSession,
													columnVisibility: columnVisibilitySession,
												}}
												muiToolbarAlertBannerProps={{
													color: 'error',
													children: <>{sessionListError}</>,
												}}
												initialState={{
													columnVisibility: { createdAt: false },
													density: 'compact',
												}}
												rowCount={sessionTotal}
												manualPagination
												manualFiltering
												manualSorting
												onSortingChange={setSortingSession}
												onGlobalFilterChange={setGlobalFilterSession}
												onColumnFiltersChange={setColumnFiltersSession}
												onPaginationChange={setPaginationSession}
												onColumnVisibilityChange={setColumnVisibilitySession}
												renderRowActions={({ row }) => (
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															gap: '1rem',
														}}
													>
														<Box
															sx={{
																display: 'flex',
																justifyContent: 'center',
																gap: '1rem',
															}}
														>
															{!row.original.endAt && (
																<>
																	{isAllowed(
																		[
																			EPermission.PAM_TARGETS_READ_ALL,
																			EPermission.PAM_TARGETS_READ_OWN,
																		],
																		false,
																	) && (
																		<Tooltip
																			title={t(
																				'page.pam.detail.tooltips.preview',
																			)}
																			placement='left'
																			enterDelay={500}
																			arrow
																		>
																			<IconButton
																				onClick={handlePreview(row.original.id)}
																			>
																				<VisibilityIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																</>
															)}
															{row.original.endAt && row.original.recordingPath && (
																<>
																	{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
																		<Tooltip
																			title={t(
																				'page.pam.detail.tooltips.playRecording',
																			)}
																			placement='left'
																			enterDelay={500}
																			arrow
																		>
																			<IconButton
																				onClick={handlePlay(
																					row.original.recordingPath,
																				)}
																			>
																				<PlayArrowIcon color={'success'} />
																			</IconButton>
																		</Tooltip>
																	)}
																	{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
																		<Tooltip
																			title={t(
																				'page.pam.detail.tooltips.downloadRecording',
																			)}
																			placement='bottom'
																			enterDelay={500}
																			arrow
																		>
																			<IconButton
																				onClick={handleDownload(
																					row.original.recordingPath,
																				)}
																			>
																				<DownloadIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																	{isAllowed(
																		[
																			EPermission.PAM_TARGETS_READ_ALL,
																			EPermission.PAM_TARGETS_READ_OWN,
																		],
																		false,
																	) &&
																		row.original.targetType ===
																			ESessionTarget.SSH && (
																			<Tooltip
																				title={t(
																					'page.sessions.tooltips.downloadTypescript',
																				)}
																				placement='bottom'
																				enterDelay={500}
																				arrow
																			>
																				<IconButton
																					onClick={handleDownload(
																						getTypescriptUrl(
																							row.original.id,
																						),
																					)}
																				>
																					<GetAppOutlinedIcon />
																				</IconButton>
																			</Tooltip>
																		)}
																</>
															)}
														</Box>
													</Box>
												)}
												renderToolbarInternalActions={({ table }) => (
													<Box sx={{ display: 'flex', gap: '1rem' }}>
														<MRTToggleGlobalFilterButton table={table} />
														<MRTToggleFiltersButton table={table} />
														<MRTShowHideColumnsButton table={table} />
														{/* <Tooltip title={t('page.pam.detail.tooltips.export')} enterDelay={500}>
													<IconButton>
														<SaveAltIcon />
													</IconButton>
												</Tooltip> */}
														<MRTToggleDensePaddingButton table={table} />
														<MRTFullScreenToggleButton table={table} />
													</Box>
												)}
												displayColumnDefOptions={{
													'mrt-row-actions': {
														header: t('page.pam.detail.table.header.actions'),
													},
													'mrt-row-select': {
														enableHiding: true,
														visibleInShowHideMenu: false,
													},
												}}
												muiTablePaperProps={({ table }) => ({
													style: {
														zIndex: table.getState().isFullScreen ? 1100 : undefined,
														boxShadow: 'none',
														outline: '1px solid #e0e0e0',
													},
												})}
												editDisplayMode='modal'
												positionActionsColumn='last'
												muiTableBodyRowProps={({ row }) => ({
													onClick:
														(
															isAllowed(
																[
																	EPermission.PAM_SESSIONS_READ_ALL,
																	EPermission.ACTIONS_READ_OWN,
																],
																false,
															)
														) ?
															handleRedirect(`/pam/sessions/${row.original.id}`)
														:	undefined,
													sx: { cursor: 'pointer' },
												})}
												muiSelectCheckboxProps={() => ({
													sx: {
														width: '50px',
														height: '50px',
													},
												})}
												muiSelectAllCheckboxProps={() => ({
													sx: {
														width: '50px',
														height: '50px',
													},
												})}
												muiTableHeadCellProps={() => ({
													sx: {
														verticalAlign: 'baseline',
													},
												})}
												localization={MRTLocalization}
											/>
										</LocalizationProvider>
									</Stack>

									<Stack spacing={2}>
										<SectionHeader
											title={t('page.pam.detail.subtitle.userList')}
											description={t('page.pam.detail.table.description.userList')}
										/>
										<MaterialReactTable
											columns={columnsUserList}
											data={userEntities}
											enableStickyHeader={false}
											state={{
												isLoading: userListIsLoading,
												showAlertBanner: userListError !== null,
												pagination: paginationUser,
												showProgressBars: userListIsRefetching,
												columnFilters: columnFiltersUser,
												globalFilter: globalFilterUser,
												sorting: sortingUser,
												columnVisibility: columnVisibilityUser,
											}}
											muiToolbarAlertBannerProps={{
												color: 'error',
												children: <>{userListError}</>,
											}}
											initialState={{
												columnVisibility: { createdAt: false },
												density: 'compact',
											}}
											rowCount={userTotal}
											manualPagination
											manualFiltering
											manualSorting
											onSortingChange={setSortingUser}
											onGlobalFilterChange={setGlobalFilterUser}
											onColumnFiltersChange={setColumnFiltersUser}
											onPaginationChange={setPaginationUser}
											onColumnVisibilityChange={setColumnVisibilityUser}
											renderToolbarInternalActions={({ table }) => (
												<Box sx={{ display: 'flex', gap: '1rem' }}>
													<MRTToggleGlobalFilterButton table={table} />
													<MRTToggleFiltersButton table={table} />
													<MRTShowHideColumnsButton table={table} />
													{/* <Tooltip title={t('page.pam.detail.tooltips.export')} enterDelay={500}>
												<IconButton>
													<SaveAltIcon />
												</IconButton>
											</Tooltip> */}
													<MRTToggleDensePaddingButton table={table} />
													<MRTFullScreenToggleButton table={table} />
												</Box>
											)}
											displayColumnDefOptions={{
												'mrt-row-select': {
													enableHiding: true,
													visibleInShowHideMenu: false,
												},
											}}
											muiTablePaperProps={({ table }) => ({
												style: {
													zIndex: table.getState().isFullScreen ? 1100 : undefined,
													boxShadow: 'none',
													outline: '1px solid #e0e0e0',
												},
											})}
											muiSelectCheckboxProps={() => ({
												sx: {
													width: '50px',
													height: '50px',
												},
											})}
											muiSelectAllCheckboxProps={() => ({
												sx: {
													width: '50px',
													height: '50px',
												},
											})}
											muiTableHeadCellProps={() => ({
												sx: {
													verticalAlign: 'baseline',
												},
											})}
											editDisplayMode='modal'
											positionActionsColumn='last'
											localization={MRTLocalization}
										/>
									</Stack>
								</>
							)}

							{isAllowed([EPermission.PAM_RECORDINGS_READ]) && recordingUrl.length > 0 && (
								<SessionPlayerDialog
									open={openPlayerDialog}
									src={recordingUrl}
									onClose={handleOnClose}
								/>
							)}
						</Stack>
					</Stack>

					{isAllowed([EPermission.PAM_TARGETS_UPDATE]) && !isAudit && !isDeleted && (
						<Link to={`/pam/${targetType.toLocaleLowerCase()}/edit/${id}`}>
							<FloatingButtonEdit
								ariaLabel={t('page.pam.detail.ariaLabel.editTarget')}
								tooltipTitle={t('page.pam.detail.tooltips.editTarget')}
							/>
						</Link>
					)}
				</Stack>
			}
		</>
	);
};
