import { z } from 'zod';

export const userListSchema = z.object({
	id: z.number(),
	name: z.string(),
	surname: z.string().optional(),
	title: z.string().optional(),
	email: z.string().min(5).max(64),
	status: z.string(),
	createdAt: z.string(),
	lastLoggedAt: z.string().optional(),
	groups: z.array(
		z
			.object({
				id: z.number(),
				name: z.string(),
				description: z.string().optional(),
			})
			.optional(),
	),
});
