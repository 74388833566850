/* eslint-disable camelcase */
import * as React from 'react';
import { Link } from '../../../components/Link/Link';

import {
	MRT_ToggleDensePaddingButton,
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton,
	MRT_PaginationState,
	MRT_ColumnFiltersState,
	MRT_SortingState,
	MRT_RowSelectionState,
	MaterialReactTable,
} from 'material-react-table';
import {
	Microsoft as MicrosoftIcon,
	GitHub as GitHubIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { SvgIconTypeMap } from '@mui/material';

import { Button, Typography } from '@mui/material';
import { UserDetailDto } from '../../../api/Api';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import styled from 'styled-components';
import { FloatingButtonAdd } from '../../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const StyledModal = styled(Box)`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400;
	background: #fff;
	border: 2px solid #000;
	-webkit-box-shadow: 1px 8px 43px 7px rgba(66, 68, 90, 1);
	-moz-box-shadow: 1px 8px 43px 7px rgba(66, 68, 90, 1);
	box-shadow: 1px 8px 43px 7px rgba(66, 68, 90, 1);
	padding: 15px;
`;

export interface IServiceProviders {
	icon?: OverridableComponent<SvgIconTypeMap> & { muiName: string };
	id?: number;
	name: string;
	description: string;
	created: string;
	status?: string;
	edit?: string;
}

export const SAMLServiceProvidersLists: React.FC = (): JSX.Element => {
	const { MRTLocalization } = useMRTLocalization();

	const [open, setOpen] = React.useState(false);
	const handleClickOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	//data and fetching state
	const [data, setData] = React.useState<UserDetailDto[]>([]);
	const [total, setTotal] = React.useState(0);
	const [error, setError] = React.useState<string | null>(null);
	const [isLoading, setIsLoading] = React.useState(false);
	const [isRefetching, setIsRefetching] = React.useState(false);

	//table state
	const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([]);
	const [globalFilter, setGlobalFilter] = React.useState('');
	const [sorting, setSorting] = React.useState<MRT_SortingState>([]);
	const [pagination, setPagination] = React.useState<MRT_PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const providersData: IServiceProviders[] = [
		{
			id: 0,
			name: 'Microsoft Office 365',
			description: 'Online version of Microsoft Office 365',
			created: '13.7.2023 12:17',
			status: 'enabled',
			icon: MicrosoftIcon,
		},
		{
			id: 1,
			name: 'Microsoft login',
			description: 'Microsoft cloud',
			created: '13.7.2023 12:17',
			status: 'enabled',
			icon: MicrosoftIcon,
		},
		{
			id: 2,
			name: 'GitHub',
			description: 'Where software is hosted',
			created: '13.7.2023 12:17',
			status: 'disabled',
			icon: GitHubIcon,
		},
	];

	const columns = React.useMemo<MRT_ColumnDef<IServiceProviders>[]>(
		() => [
			{
				accessorFn: (row) => row.icon, // Access the icon component
				accessorKey: 'icon',
				header: '',
				size: 50,
				Cell: ({ cell }) => {
					const IconComponent = cell.getValue() as
						| (OverridableComponent<SvgIconTypeMap> & { muiName: string })
						| undefined;

					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{IconComponent ?
								<IconComponent />
							:	null}
						</Box>
					);
				},
			},
			{
				accessorFn: (row) => `${row.name}`,
				accessorKey: 'name',
				header: 'Name',
				size: 250,
				// eslint-disable-next-line react/prop-types
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},

			{
				accessorKey: 'description',
				header: 'Description',
			},
			{
				accessorKey: 'created',
				header: 'Created',
			},
			{
				accessorKey: 'status',
				header: 'Status',
				size: 250,
				// eslint-disable-next-line react/prop-types
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Stack direction='row' spacing={1}>
							{renderedCellValue === 'enabled' ?
								<Chip label={renderedCellValue} color='success' />
							:	<Chip label={renderedCellValue} color='error' />}
						</Stack>
					</Box>
				),
			},
			{
				accessorKey: 'edit',
				header: ' ',
				Cell: () => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Tooltip arrow placement='right' title='Edit'>
							<span>
								<Link to='/saml/providers/edit'>
									<IconButton>
										<EditIcon />
									</IconButton>
								</Link>
							</span>
						</Tooltip>
						<Tooltip arrow placement='right' title='Delete'>
							<IconButton color='error' onClick={handleClickOpen}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				),
			},
		],
		[],
	);

	const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});

	return (
		<Box>
			<Box
				sx={{
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Service Providers</Typography>
			</Box>
			<MaterialReactTable
				columns={columns}
				data={providersData}
				enableStickyHeader={false}
				state={{
					isLoading: isLoading,
					showAlertBanner: error !== null,
					pagination,
					rowSelection,
					showProgressBars: isRefetching,
				}}
				muiToolbarAlertBannerProps={{
					color: 'error',
					children: error,
				}}
				rowCount={total}
				manualPagination
				onPaginationChange={setPagination}
				enableRowSelection
				onRowSelectionChange={setRowSelection}
				initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
				renderToolbarInternalActions={({ table }) => (
					<Box>
						<MRT_ToggleGlobalFilterButton table={table} />
						<MRT_ToggleFiltersButton table={table} />
						<MRT_ShowHideColumnsButton table={table} />
						{/* <Tooltip arrow title='Export'>
							<IconButton>
								<SaveAltIcon />
							</IconButton>
						</Tooltip> */}

						<MRT_ToggleDensePaddingButton table={table} />
						<MRT_ToggleFullScreenButton table={table} />
					</Box>
				)}
				displayColumnDefOptions={{
					'mrt-row-actions': {
						muiTableHeadCellProps: {
							align: 'center',
						},
						size: 120,
						enableHiding: true,
					},
					'mrt-row-select': {
						enableHiding: true,
						visibleInShowHideMenu: false,
					},
				}}
				muiTablePaperProps={({ table }) => ({
					style: {
						zIndex: table.getState().isFullScreen ? 1250 : undefined,
						boxShadow: 'none',
						outline: '1px solid #e0e0e0',
					},
				})}
				muiSelectCheckboxProps={() => ({
					sx: {
						width: '50px',
						height: '50px',
					},
				})}
				muiSelectAllCheckboxProps={() => ({
					sx: {
						width: '50px',
						height: '50px',
					},
				})}
				muiTableHeadCellProps={() => ({
					sx: {
						verticalAlign: 'baseline',
					},
				})}
				localization={MRTLocalization}
			/>

			<Link to='/saml/providers/new'>
				<FloatingButtonAdd ariaLabel={'Add Service Provider'} />
			</Link>

			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={open}>
					<StyledModal>
						<Typography id='transition-modal-title' variant='h6' component='h2'>
							Are you sure you want to delete it?
						</Typography>
						<Typography id='transition-modal-description' sx={{ mt: 2 }}>
							<Button onClick={handleClose}>Cancel</Button>
							<Button onClick={handleClose}>Confirm</Button>
						</Typography>
					</StyledModal>
				</Fade>
			</Modal>
		</Box>
	);
};
