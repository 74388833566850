import { useMemo, useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { enUS as en, sk } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import { DateTimeFilterType, DateTimeFilterProps, NullableDayjs } from './types';
import { getRangeDateTimeFilterValue, replaceRangeDateTimeFilterValue, toNullableDate, toNullableDayjs } from './utils';
import { useCurrentLanguage } from '../../contexts/LocalizationContext/LocalizationContext';

export const DateTimeFilter: React.FC<DateTimeFilterProps> = ({ value, setValue }) => {
	const [dateTimeFilterType, setDateTimeFilterType] = useState<DateTimeFilterType>('date');

	const { t } = useTranslation();

	const currentLanguage = useCurrentLanguage();

	const locale = useMemo(() => {
		const locales = {
			en: en,
			sk: sk,
		};

		return locales[currentLanguage] || en;
	}, [currentLanguage]);

	const setEndDate = (endDate: NullableDayjs) => setValue(replaceRangeDateTimeFilterValue({ endDate, value }));
	const setStartDate = (startDate: NullableDayjs) =>
		setValue(
			replaceRangeDateTimeFilterValue({
				startDate,
				value,
			}),
		);

	const [startDate, endDate] = getRangeDateTimeFilterValue(value);

	return (
		<>
			<Typography>{t('component.auditLogTimeline.dateTimeFilter.title')}</Typography>
			<FormControl>
				<RadioGroup
					row
					name='audit-log-radio-buttons'
					value={dateTimeFilterType}
					onChange={(_, value) => setDateTimeFilterType(value as DateTimeFilterType)}
				>
					<FormControlLabel
						value='date'
						control={<Radio />}
						label={t('component.auditLogTimeline.dateTimeFilter.radioButtons.date')}
					/>
					<FormControlLabel
						value='range'
						control={<Radio />}
						label={t('component.auditLogTimeline.dateTimeFilter.radioButtons.range')}
					/>
				</RadioGroup>
			</FormControl>
			{dateTimeFilterType === 'date' && (
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
					<DateTimePicker
						disableFuture
						label={t('component.auditLogTimeline.dateTimeFilter.labels.date')}
						value={Array.isArray(value) ? null : toNullableDate(value)}
						onAccept={(date) => setValue(toNullableDayjs(date))}
						slotProps={{
							field: {
								clearable: true,
								onClear: () => setValue(null),
							},
						}}
					/>
				</LocalizationProvider>
			)}
			{dateTimeFilterType === 'range' && (
				<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
					<DateTimePicker
						disableFuture
						label={t('component.auditLogTimeline.dateTimeFilter.labels.startDate')}
						value={startDate ? startDate.toDate() : null}
						onAccept={(date) => setStartDate(toNullableDayjs(date))}
						slotProps={{
							field: {
								clearable: true,
								onClear: () => setStartDate(null),
							},
						}}
					/>
					<DateTimePicker
						disableFuture
						label={t('component.auditLogTimeline.dateTimeFilter.labels.endDate')}
						value={endDate ? endDate.toDate() : null}
						onAccept={(date) => setEndDate(toNullableDayjs(date))}
						slotProps={{
							field: {
								clearable: true,
								onClear: () => setEndDate(null),
							},
						}}
					/>
				</LocalizationProvider>
			)}
		</>
	);
};
