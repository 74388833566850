import * as React from 'react';
import { Typography, Grid, Box, FormGroup, Button, Link } from '@mui/material';
import { AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getIdentityStoreAuthenticationSchema } from '../schemas';
import { useRegistrationStatus } from '../../../hooks/useRegistrationStatus';
import {
	DeploymentFinalizationProps,
	DeploymentFinalizationState,
	IdentityStoreAuthenticationFormState,
} from '../types';
import { ESetupSteps } from '../enums';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import { useAuthContext } from '../../../contexts/AuthContext/AuthContext';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { SEO } from '../../../components/SEO/SEO';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { WATCHED_KEYS } from '../constants';
import { useKeyPress } from '../../../hooks/useKeyPress';
import AppStoreIcon from '../../../assets/icons/AppStoreIcon';
import GooglePlayIcon from '../../../assets/icons/GooglePlayIcon';

export const DeploymentFinalization: React.FC<DeploymentFinalizationProps> = (props): JSX.Element => {
	useScrollToTop();
	const device = useDeviceDetect();
	const authContext = useAuthContext();
	const [startPolling, setStartPolling] = React.useState(false);
	const isRegistered = useRegistrationStatus('/api/v1/status/registered', startPolling);
	const { setInitialSetupIndex, setIndexPage, getCurrentStep, initialSetupIndex } = props;
	const api = useSwaggerApi();
	const [setupQrCode, setSetupQrCode] = React.useState<string | null>(null);
	const { t } = useTranslation();

	const [deploymentFinalizationState, setDeploymentFinalizationState] = React.useState<DeploymentFinalizationState>({
		loading: false,
		loaded: false,
		error: null,
	});
	const {
		handleSubmit,
		register,
		formState: { errors },
	} = useForm<IdentityStoreAuthenticationFormState>({
		mode: 'onChange',
		resolver: zodResolver(getIdentityStoreAuthenticationSchema(t)),
	});
	const pollIntervalRef = React.useRef<NodeJS.Timeout | null>(null);

	React.useEffect(() => {
		if (isRegistered) {
			const checkAppStatus = async () => {
				try {
					const response = await api.status.getAppStatus();
					if (response.data === 'DEVELOPMENT' || response.data === 'PRODUCTION') {
						if (pollIntervalRef.current) {
							clearInterval(pollIntervalRef.current);
						}
						authContext.handleOnLoadUser(true);
					}
				} catch (error) {
					// eslint-disable-next-line no-console
					console.error(error);
				}
			};

			const initialTimeout = setTimeout(() => {
				checkAppStatus();
				pollIntervalRef.current = setInterval(checkAppStatus, 2000);
			}, 5000);

			return () => {
				clearTimeout(initialTimeout);
				if (pollIntervalRef.current) {
					clearInterval(pollIntervalRef.current);
				}
			};
		}
	}, [isRegistered]);

	const handleOnSubmit = React.useCallback(async (formValues: IdentityStoreAuthenticationFormState) => {
		if (deploymentFinalizationState.loading) {
			return;
		}
		try {
			setDeploymentFinalizationState({
				loading: true,
				loaded: false,
				error: null,
			});
			const response = await api.setup.authenticateIdentityStore(formValues);
			setDeploymentFinalizationState({
				loading: false,
				loaded: true,
				error: null,
			});
			enqueueSnackbar(t('page.setupWizard.actionMessages.identityStoreSuccessfullyAuthenticated'), {
				variant: 'success',
				persist: false,
			});
			setSetupQrCode(response.data);
			setInitialSetupIndex(ESetupSteps.DEPLOYMENT_FINALIZATION);
			setStartPolling(true);
		} catch (error) {
			setDeploymentFinalizationState({
				loading: false,
				loaded: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	const handleKeyDown = useKeyPress(WATCHED_KEYS, handleSubmit(handleOnSubmit), ['BUTTON']);

	const handleOnBack = React.useCallback(async () => {
		if (deploymentFinalizationState.loading) {
			return;
		}

		setDeploymentFinalizationState({
			loading: true,
			loaded: false,
			error: null,
		});
		try {
			const response = await api.setup.setBackSetupProgress();
			const step = response.data;

			setDeploymentFinalizationState({
				loading: false,
				loaded: true,
				error: null,
			});

			setIndexPage(getCurrentStep(step));
		} catch (error) {
			setDeploymentFinalizationState({
				loading: false,
				loaded: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	return (
		<Box onKeyDown={handleKeyDown}>
			<SEO
				title={t('page.setupWizard.texts.deploymentFinalization.title')}
				description={t('page.setupWizard.texts.deploymentFinalization.description')}
			/>
			{initialSetupIndex === ESetupSteps.DEPLOYMENT_FINALIZATION && setupQrCode ?
				<Grid container spacing={2}>
					<Grid item>
						<Typography align='center' variant='h6' component='h1'>
							{t('page.setupWizard.texts.deploymentFinalization.login')}
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						{setupQrCode && (
							<Box
								loading='lazy'
								component='img'
								width={350}
								src={`data:image/svg+xml;utf8,${encodeURIComponent(setupQrCode)}`}
								draggable={false}
								sx={{
									alignItems: 'center',
									cursor: 'none',
								}}
							/>
						)}
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography align='center'>
									{t('page.setupWizard.texts.deploymentFinalization.downloadApp')}
								</Typography>
								<Grid container spacing={2} width='500px' justifySelf='center'>
									<Grid item xs>
										<Link
											href='https://play.google.com/store/apps/details?id=com.v4xclbr'
											target='_blank'
											rel='noreferrer'
										>
											<GooglePlayIcon />
										</Link>
									</Grid>
									<Grid item xs>
										<Link
											href='https://apps.apple.com/us/app/excalibur-v4/id6630382972?l=cs'
											target='_blank'
											rel='noreferrer'
										>
											<AppStoreIcon />
										</Link>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			:	<>
					<FormGroup
						sx={{
							marginBottom: 2,
							marginTop: 2,
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									name={'username'}
									register={register}
									label={t('page.setupWizard.texts.deploymentFinalization.username.label')}
									disabled={deploymentFinalizationState.loading}
									error={errors.username}
									helperText={t('page.setupWizard.texts.deploymentFinalization.username.helperText')}
								/>
							</Grid>
							<Grid item xs={12}>
								<PasswordField
									name={'password'}
									register={register}
									label={t('page.setupWizard.texts.deploymentFinalization.password.label')}
									error={errors.password}
									disabled={deploymentFinalizationState.loading}
									helperText={t('page.setupWizard.texts.deploymentFinalization.password.helperText')}
								/>
							</Grid>
						</Grid>
					</FormGroup>
					<Grid
						item
						xs={12}
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Button
							fullWidth={device !== 'desktop'}
							variant='outlined'
							type='submit'
							onClick={handleOnBack}
						>
							{t('page.setupWizard.texts.SMTP.back')}
						</Button>
						<Button
							fullWidth={device !== 'desktop'}
							variant='contained'
							type='submit'
							onClick={handleSubmit(handleOnSubmit)}
						>
							{t('page.setupWizard.texts.deploymentFinalization.submitButton')}
						</Button>
					</Grid>
				</>
			}
		</Box>
	);
};
