import { PAMTargetAuditModel, PAMTargetDetailModel } from '../../api/Api';

export const dispatchPAMSession = (sessionID: number): void => {
	const data = { sessionID };
	const token = JSON.stringify(data);
	const base64Token = btoa(token);
	const encodedToken = encodeURIComponent(base64Token);
	const url = `/pam/client/?token=${encodedToken}`;
	const newTab = window.open(url, '_blank', 'noopener noreferrer');
	newTab?.focus();
};

export const isAuditData = (data: PAMTargetAuditModel | PAMTargetDetailModel): data is PAMTargetAuditModel => {
	if ('revType' in data) {
		return true;
	}

	return false;
};

export const omitUnusedAuditLogFields = (auditLog: PAMTargetAuditModel) => {
	const { createdAt, isLatest, tenantID, type, ...rest } = auditLog;

	return rest;
};
