import { useState } from 'react';
import {
	ArrowRightAlt as ArrowRightAltIcon,
	Delete as DeleteIcon,
	FilterList as FilterListIcon,
} from '@mui/icons-material';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineItem,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	TimelineSeparator,
} from '@mui/lab';
import { Box, Button, Divider, Grid, Stack, TablePagination, Typography, useMediaQuery, useTheme } from '@mui/material';
import { format, isSameDay } from 'date-fns';
import { Dayjs } from 'dayjs';
import { MRT_ColumnFiltersState } from 'material-react-table';
import { useTranslation } from 'react-i18next';

import { DateTimeFilter } from './DateTimeFilter';
import { Heading } from '../Heading/Heading';
import { Preloader } from '../Preloader/Preloader';
import { RevTypeFilter } from './RevTypeFilter';
import { UserFilter } from './UserFilter/UserFilter';
import { AuditLogTimelineProps } from './types';
import { ERevType } from '../../api/Api';
import { getRevTypeColor, getChangedFields, pickValueFields, formatFieldValue, getAction } from './utils';

export const AuditLogTimeline = ({
	data,
	isLoading,
	total,
	filters,
	page,
	rowsPerPage,
	setFilters,
	setPage,
	setRowsPerPage,
	translateFieldKey,
}: AuditLogTimelineProps) => {
	const [filtersOpen, setFiltersOpen] = useState(false);

	const { t } = useTranslation();

	const theme = useTheme();
	const matchesMd = useMediaQuery(theme.breakpoints.up('md'));
	const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));
	const maxHeight = `calc(100vh - ${matchesLg ? 380 : 420}px)`;

	const onChangeFilter = ({ id, value }: MRT_ColumnFiltersState[0]) => {
		if (value === undefined) {
			setFilters?.((prev) => prev.filter((filter) => filter.id !== id));
		} else {
			setFilters?.((prev) => {
				if (prev.length === 0) {
					return [{ id, value }];
				}

				if (prev.find((filter) => filter.id === id) === undefined) {
					return [...prev, { id, value }];
				}

				return prev.map((filter) => {
					return filter.id === id ? { ...filter, value } : filter;
				});
			});
		}
	};

	const userIDColumnFilter = filters?.find((filter) => filter.id === 'modifiedBy');
	const userIDValue = userIDColumnFilter ? parseInt(userIDColumnFilter.value as string) : null;

	const revTypeColumnFilter = filters?.find((filter) => filter.id === 'revType');
	const revTypeValue = revTypeColumnFilter ? (revTypeColumnFilter.value as string) : null;

	const modifiedAtColumnFilter = filters?.filter((filter) => filter.id === 'modifiedAt') ?? [];
	const modifiedAtValue =
		modifiedAtColumnFilter.length === 1 ?
			(modifiedAtColumnFilter[0].value as Dayjs | null | Array<Dayjs | null>)
		:	null;

	return (
		<Box>
			<Grid container alignItems='center' paddingBottom={2} spacing={2}>
				<Grid item xs={12} md={8}>
					<Heading label={t('component.auditLogTimeline.title')} paddingBottom={0} />
				</Grid>
				<Grid item xs={12} md={4}>
					<Stack direction='row' justifyContent={matchesMd ? 'flex-end' : 'flex-start'} spacing={1}>
						<Button
							startIcon={<FilterListIcon />}
							onClick={() => setFiltersOpen((prev) => !prev)}
							sx={{ padding: 0, textTransform: 'none' }}
						>
							{t(`component.auditLogTimeline.${filtersOpen ? 'hideFilters' : 'showFilters'}`)}
						</Button>
					</Stack>
				</Grid>
			</Grid>
			<Grid container direction='row-reverse'>
				{filtersOpen && (
					<Grid item xs={12} md={4}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: matchesMd ? 'row' : 'column',
								gap: matchesMd ? 0 : 2,
								paddingLeft: matchesMd ? 4 : 0,
								paddingBottom: matchesMd ? 0 : 1,
								height: matchesMd ? maxHeight : 'auto',
								width: matchesMd ? 'auto' : '100%',
							}}
						>
							{matchesMd && <Divider flexItem orientation='vertical' sx={{ height: '100%' }} />}
							<Box sx={{ width: '100%' }}>
								<Stack paddingLeft={matchesMd ? 4 : 0} spacing={2} sx={{ width: '100%' }}>
									<Typography>
										<strong>{t('component.auditLogTimeline.filters')}</strong>
									</Typography>
									<UserFilter
										userID={userIDValue}
										setUserID={(value) =>
											onChangeFilter({ id: 'modifiedBy', value: value?.toString() ?? null })
										}
									/>
									<RevTypeFilter
										revType={revTypeValue}
										setRevType={(value) => onChangeFilter({ value, id: 'revType' })}
									/>
									<DateTimeFilter
										value={modifiedAtValue}
										setValue={(value) => onChangeFilter({ value, id: 'modifiedAt' })}
									/>
								</Stack>
								<Button
									color='error'
									startIcon={<DeleteIcon />}
									variant='text'
									sx={{
										marginLeft: matchesMd ? 4 : 0,
										marginTop: 2,
										textTransform: 'none',
									}}
									onClick={() => {
										setFilters([]);
									}}
								>
									{t('component.auditLogTimeline.clearFilters')}
								</Button>
							</Box>
							{!matchesMd && <Divider flexItem sx={{ width: '100%' }} />}
						</Box>
					</Grid>
				)}
				<Grid item xs={12} md={filtersOpen ? 8 : 12}>
					{isLoading && <Preloader />}
					{!isLoading && data && data.length === 0 && (
						<Typography textAlign={matchesMd ? 'left' : 'center'}>
							{t('component.auditLogTimeline.noData')}
						</Typography>
					)}
					{!isLoading && data && data.length > 0 && (
						<Timeline
							sx={{
								[`& .${timelineOppositeContentClasses.root}`]: {
									flex:
										matchesMd ?
											filtersOpen ? 0.2
											:	0.15
										:	1,
								},
								padding: 0,
								margin: 0,
							}}
						>
							{data.map((auditLog, index) => {
								const { modifiedAt, modifiedBy, revID, revType } = auditLog;

								const isDelete = revType === ERevType.Delete;
								const isFirst = index === 0;
								const isLast = index === data.length - 1;
								const displayDiff = revType === ERevType.Update && !isLast && !userIDValue;
								const isSameDayAuditLog =
									isFirst ? false : isSameDay(modifiedAt, data[index - 1].modifiedAt);
								const valueFields = pickValueFields(auditLog);
								const valueFieldKeys =
									displayDiff ?
										getChangedFields(valueFields, pickValueFields(data[index + 1]))
									:	(Object.keys(valueFields) as Array<keyof typeof valueFields>);

								return (
									<TimelineItem
										key={revID}
										sx={{ flexDirection: matchesMd ? 'row' : 'column', my: 1, minHeight: 'auto' }}
									>
										<TimelineOppositeContent
											sx={{
												paddingLeft: 0,
												paddingRight: matchesMd ? 2 : 0,
												paddingTop: 0,
												paddingBottom: matchesMd ? 0 : 1,
												width: '100%',
											}}
										>
											<Grid container rowSpacing={1}>
												<Grid item xs={12} md={8}>
													{!isSameDayAuditLog && (
														<Typography fontWeight='bold' textAlign='left'>
															{format(modifiedAt, 'dd MMM yyyy')}
														</Typography>
													)}
												</Grid>
												<Grid item xs={12} md={4}>
													<Typography textAlign={matchesMd ? 'right' : 'left'}>
														{format(modifiedAt, 'HH:mm')}
													</Typography>
												</Grid>
											</Grid>
										</TimelineOppositeContent>
										<TimelineSeparator>
											<TimelineConnector
												sx={{
													width: matchesMd ? 4 : '100%',
													height: matchesMd ? '100%' : 4,
													bgcolor: getRevTypeColor(revType),
												}}
											/>
										</TimelineSeparator>
										<TimelineContent sx={{ bgcolor: '#fafafa' }}>
											<Stack spacing={1}>
												<Typography>
													{getAction(revType, t)}{' '}
													<b>{modifiedBy || t('component.auditLogTimeline.systemUser')}</b>
												</Typography>
												{!isDelete && valueFieldKeys.length === 0 && (
													<Typography color='grey'>
														{t('component.auditLogTimeline.noChanges')}
													</Typography>
												)}
												{!isDelete &&
													valueFieldKeys.length > 0 &&
													valueFieldKeys.map((fieldKey) => (
														<Stack
															key={fieldKey}
															direction='row'
															flexWrap='wrap'
															spacing={1}
														>
															<Typography color='grey'>
																{translateFieldKey(fieldKey)}:{' '}
															</Typography>
															{displayDiff ?
																<>
																	<Typography
																		noWrap
																		color='red'
																		sx={{
																			textDecoration: 'line-through',
																		}}
																	>
																		{formatFieldValue({
																			t,
																			value: data[index + 1][fieldKey],
																		})}
																	</Typography>
																	<ArrowRightAltIcon fontSize='small' />
																	<Typography noWrap color='green' fontWeight='bold'>
																		{formatFieldValue({
																			t,
																			value: auditLog[fieldKey],
																		})}
																	</Typography>
																</>
															:	<>
																	<Typography
																		color={
																			revType === ERevType.Insert ?
																				'green'
																			:	'black'
																		}
																		fontWeight='bold'
																	>
																		{formatFieldValue({
																			t,
																			value: auditLog[fieldKey],
																		})}
																	</Typography>
																</>
															}
														</Stack>
													))}
											</Stack>
										</TimelineContent>
									</TimelineItem>
								);
							})}
						</Timeline>
					)}
				</Grid>
			</Grid>
			<TablePagination
				component='div'
				count={total}
				labelDisplayedRows={({ from, to, count }) =>
					`${from}-${to} ${t('component.auditLogTimeline.of')} ${count}`
				}
				labelRowsPerPage={t('component.auditLogTimeline.rowsPerPage')}
				page={page}
				onPageChange={(_, newPage) => setPage(newPage)}
				rowsPerPage={rowsPerPage}
				showFirstButton={total > rowsPerPage}
				showLastButton={total > rowsPerPage}
				rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
				onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value))}
			/>
		</Box>
	);
};
