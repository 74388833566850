import dayjs from 'dayjs';
import { TFunction } from 'i18next';

import {
	AuditLogData,
	AuditLogFieldValue,
	AuditLogValueFields,
	DateTimeFilterValue,
	FormatAuditLogFieldValueParams,
	NullableDate,
	NullableDayjs,
	RangeDateTimeFilterValue,
	ReplaceDateTimeFilterArrayValueParams,
} from './types';
import { ERevType } from '../../api/Api';

export const getRevTypeColor = (revType: ERevType) => {
	switch (revType) {
		case ERevType.Insert:
			return 'green';
		case ERevType.Update:
			return 'primary.light';
		case ERevType.Delete:
			return 'red';
	}
};

export const pickValueFields = (auditLog: AuditLogData): Record<string, AuditLogFieldValue> => {
	const { modifiedAt, modifiedBy, revID, revType, ...rest } = auditLog;

	return rest;
};

export const getChangedFields = (current: AuditLogValueFields, prev: AuditLogValueFields) => {
	const changedFields: Array<string> = [];

	for (const field of Object.keys(current)) {
		if (current[field] !== prev[field]) {
			changedFields.push(field);
		}
	}

	return changedFields;
};

export const getAction = (revType: ERevType, t: TFunction) => {
	switch (revType) {
		case ERevType.Insert:
			return t('component.auditLogTimeline.actions.createdBy');
		case ERevType.Update:
			return t('component.auditLogTimeline.actions.updatedBy');
		case ERevType.Delete:
			return t('component.auditLogTimeline.actions.deletedBy');
	}
};

export const formatFieldValue = ({ value, t }: FormatAuditLogFieldValueParams) => {
	if (typeof value === 'boolean') {
		return value ? t('component.auditLogTimeline.values.yes') : t('component.auditLogTimeline.values.no');
	}

	if (typeof value === 'string') {
		if (!value) {
			return t('component.auditLogTimeline.values.empty');
		}

		return value;
	}

	if (value === null || value === undefined) {
		return t('component.auditLogTimeline.values.empty');
	}

	return value;
};

// revision type filter utils
export function revTypeToArray(t: TFunction) {
	return Object.values(ERevType).map((revType) => ({
		label: t(`component.auditLogTimeline.revTypeFilter.actions.${revType}`),
		id: revType,
	}));
}

// date time filter utils
export const toNullableDate = (date: NullableDayjs): NullableDate => (date ? date.toDate() : null);
export const toNullableDayjs = (date: NullableDate): NullableDayjs => (date ? dayjs(date) : null);

export const getRangeDateTimeFilterValue = (value: DateTimeFilterValue): RangeDateTimeFilterValue => {
	if (!value || !Array.isArray(value) || value.length === 0) {
		return [null, null];
	}

	return value;
};

export const replaceRangeDateTimeFilterValue = ({
	value,
	endDate: newEndDate,
	startDate: newStartDate,
}: ReplaceDateTimeFilterArrayValueParams): RangeDateTimeFilterValue => {
	let currentEndDate: NullableDayjs = null;
	let currentStartDate: NullableDayjs = null;

	if (Array.isArray(value)) {
		currentEndDate = value[1];
		currentStartDate = value[0];
	}

	return [
		newStartDate === undefined ? currentStartDate : newStartDate,
		newEndDate === undefined ? currentEndDate : newEndDate,
	];
};
